import {  propsObject } from "./types/InputProps"






const GenricLabel =(props: propsObject)=> {
    return (
      <div className="mt-3 mb-3">
        <label htmlFor={props.inputProps.inpuType} className="block text-sm font-medium text-gray-900">
          {props.inputProps.displayName}
        </label>
        <div className="mt-1">
          {props.selectedValue}
        </div>
        </div>
    )
  }


  export default GenricLabel