import { setDefaultResultOrder } from "dns"
import { useEffect, useState } from "react"
import { getFormattedDate } from "../../configs/utils";
import {  propsObject } from "./types/InputProps"






const CustomTextArea =(props: propsObject)=> {
    const { displayName, columnName, inpuType, placeHolder, id, errorMessage,isRequired, defaultValue}  = props.inputProps ;
    const [value, setValue] =useState("")
    const [error, setError] =useState(false)

    useEffect(()=>{
        if(!value && isRequired && value!=="0001-01-01T00:00:00")
        {
          setError(true)
        }
        else
        {
          setError(false)
        }


    },[
      value
    ])

    useEffect(()=>{
      if(defaultValue)
      {
        (document.getElementById(props.inputProps.columnName) as HTMLInputElement).value=defaultValue

      props.updateSelectedValue(props.inputProps.columnName,defaultValue as string, props.inputProps.inpuType);
      setValue(defaultValue)
      }
    },[defaultValue])

    useEffect(()=>{
      if(props.selectedValue)
      {
      if(props.inputProps.inpuType=="date")
      {
        
      (document.getElementById(props.inputProps.columnName) as HTMLInputElement).value=getFormattedDate(props.selectedValue, "YYYY-MM-DD")
      }
      else if (props.inputProps.inpuType=="datetime-local")
      {
        (document.getElementById(props.inputProps.columnName) as HTMLInputElement).value=getFormattedDate(props.selectedValue, "YYYY-MM-DDTHH:mm")
      }
      else
      {
        (document.getElementById(props.inputProps.columnName) as HTMLInputElement).value=props.selectedValue
      }
      setError(false)
    }
    else
    {
      (document.getElementById(props.inputProps.columnName) as HTMLInputElement).value=""
     
    }

    },[props.selectedValue])

    return (
      <div>
        <label htmlFor={inpuType} className="block text-sm font-medium text-gray-900">
          {displayName}
        </label>
        <div className="mt-1">
        <textarea
          rows={props.inputProps.rows}
          cols={props.inputProps.cols}
          name={columnName}
          onChange= {(event)=>{props.updateSelectedValue(columnName,event.currentTarget.value, inpuType); setValue(event.currentTarget.value) } }
          id={columnName}
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
          placeholder={placeHolder}
        />
       
        </div>
            {
                error && props.clickSubmit?
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                        {errorMessage}
                    </p> : ""
                                            }
      </div>
    )
  }


  export default CustomTextArea