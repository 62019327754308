import { useEffect, useState } from "react"
import { InputProps } from "../../../components/atoms/types/InputProps"
import GenricDialog from "../../../components/organisms/Dialog"
import FormObject from "../../../components/organisms/Form"
import GenricPanel, { PanelProps } from "../../../components/organisms/Panel"
import AdminSearch from "../../../components/organisms/Search/AdminSearch"
import GenricTable from "../../../components/organisms/Table/GenericTable"
import { FormProps } from "../../../components/organisms/types/FormProps"
import { ApiRequestProps, apiURL } from "../../../configs/appliationConfig"


const ClientVacancyList = (props: any) => {

  const [keyId, setKeyId] = useState(-1)
  const [newClientId, setClientsId] = useState(-1)
  const [refresh, setRefresh] = useState(false)
  //const [loadApi, setLoadApi] = useState<ApiRequestProps>();
  const [clientTableProps, setClientTableProps] = useState<any>()

  const submitApiFormOtherComponent= (apiReq: ApiRequestProps)=>
  {
   
    (clientTable.api as ApiRequestProps) = apiReq
    setClientsId(parseInt((apiReq.payload as any).clientid))
    setClientTableProps(clientTable)
  }

 

  let editClick = (event: any , id: number) => {
    if(parseInt((document.getElementById("hdnClientId") as HTMLInputElement).value) >0)
    {
    event.preventDefault();
    setKeyId(id)
    }
    else{
      alert('Please select client and click on search')
    }
  
  }

 

  

  const doRefresh =(ref: boolean)=>{
    setRefresh(ref)
  }

  useEffect(()=>{
    setClientTableProps(clientTable)
  },[])

  

   const clientTable = {
    api: {

      url: apiURL("/api/Client/GetClientVacancyList"),
      method: "post",
      payload: {
        clientid: 0,
        positionid:0
      }
    },
  

    columnConfigs: [
      {
        key: "id",
        displayName: "Client Name",
        columnName: "name",
        columnType: "text"
      },
      

      {
        displayName: "Edit",
        columnName: "id",
        columnType: "hyperlink",
        func: editClick
      },

      {
     
        displayName: "Sub Entity Name",
        columnName: "subentity",
        columnType: "text"
      },

      {
     
        displayName: "Position",
        columnName: "position",
        columnType: "text"
      },

    

      {
        displayName: "Contact Person",
        columnName: "contact",
        columnType: "text"

      },

      {
     
        displayName: "Designation",
        columnName: "designation",
        columnType: "text"
      },


      {
        displayName: "Office Phone",
        columnName: "officephone",
        columnType: "text"

      },

      {
        displayName: "Mobile",
        columnName: "mobile",
        columnType: "text"

      },
      {
        displayName: "Email",
        columnName: "email",
        columnType: "text"

      },


     

      {
        displayName: "Sub Entity Contact Person",
        columnName: "subcontact",
        columnType: "text"

      },
      {
     
        displayName: "Sub Entity Designation",
        columnName: "subdesignation",
        columnType: "text"
      },


      {
        displayName: "Sub Entity Office Phone",
        columnName: "subofficephone",
        columnType: "text"

      },

      {
        displayName: "Sub Entity Mobile",
        columnName: "submobile",
        columnType: "text"

      },
      {
        displayName: "Sub Entity Email",
        columnName: "subemail",
        columnType: "text"

      },
      {
        displayName: "No Of Vacancies",
        columnName: "noofvaccancies",
        columnType: "text"

      },

      {

        displayName:"Place of Work",
        columnName: "placeofwork",
        columnType: "text"
    },
  
    {
  
      displayName: "Experience",
      columnName: "experience",
      columnType: "text"
  },
  
  
  {
  
    displayName: "Qualification",
    columnName: "qualification",
    columnType: "text"
  },
  
  {
  
    displayName: "Salary Range",
    columnName: "salaryrange",
    columnType: "text"
  },
  
  {
  
    displayName: "Engagement Type",
    columnName: "deployment",
    columnType: "text"
  },

  {
  
    displayName: "Full Time/Part Time",
    columnName: "employmenttype",
    columnType: "text"
  },
  
  {
  
    displayName:"Period of Engagement(in months)",
    columnName: "periodofengagement",
    columnType: "text"
  },
  
  {
  
    displayName:"Working Hours Per Day",
    columnName: "workinghoursperday",
    columnType: "text"
  },
  
  {
  
    displayName:"Working Hours Per Week",
    columnName: "workinghoursperwek",
    columnType: "text"
  },
  {
  
    displayName:"Working Days Per Week",
    columnName: "workingdaysperweek",
    columnType: "text"
  },
  
  {
  
    displayName:"Probation Period",
    columnName: "probationperiod",
    columnType: "text"
  },
  
  
  {
  
    displayName:"Annual Leave(No of Days)",
    columnName: "annualleave",
    columnType: "text"
  },
  
  {
  
    displayName:"Compassionate Leave(No of Days)",
    columnName: "compassionateleave",
    columnType: "text"
  },
  
  {
  
    displayName:"Transport Allowance",
    columnName: "transportallowance",
    columnType: "text"
  },
  
  {
  
    displayName:"Medical Benefits",
    columnName: "medicalbenefits",
    columnType: "text"
  },

  {
  
    displayName:"Client Docs",
    columnName: "clientuploads",
    columnType: "files"
  },

  {
  
    displayName:"EHC Docs",
    columnName: "ehcuploads",
    columnType: "files"
  },
  
  
  
  {
    displayName: "Start Date",
    columnName: "startdate",
    columnType: "date",
    format: "DD MMM yyyy"
  },

      {
        displayName: "Requested Date",
        columnName: "requesteddate",
        columnType: "date",
        format: "DD MMM yyyy"
      },

      {
        displayName: "Closing Date",
        columnName: "closingdate",
        columnType: "date",
        format: "DD MMM yyyy"
      },
      {
        displayName: "Response Date",
        columnName: "responsedate",
        columnType: "date",
        format: "DD MMM yyyy"
      },

      {
        displayName: "EHC Remark",
        columnName: "remark",
        columnType: "text"

      },

      {
        displayName: "Client Remark",
        columnName: "remarkbyclient",
        columnType: "text"

      },
      {
        displayName: "Interview Date From",
        columnName: "interviewperiodfrom",
        columnType: "date",
        format: "DD MMM yyyy"

      },
      {
        displayName: "Interview Date To",
        columnName: "interviewperiodto",
        columnType: "date",
        format: "DD MMM yyyy"

      },
      {
        displayName: "Person-In-Charge(Educare)",
        columnName: "personincharge",
        columnType: "text"

      },
      {
        displayName: "% of Monthly Salary or Lump Sum",
        columnName: "percentofmonthlysalary",
        columnType: "text"

      },
      {
        displayName: "% of Performance Bonus or Lump Sum",
        columnName: "percentofperformancebonus",
        columnType: "text"

      },
      {
        displayName: "Reported by",
        columnName: "reportedby",
        columnType: "text"

      },
      {
        displayName: "Date of Report",
        columnName: "dateofreport",
        columnType: "date",
        format: "DD MMM yyyy"
      },

      {
        displayName: "Reported To",
        columnName: "reportedto",
        columnType: "text"

      },

      {
        displayName: "Invoice No",
        columnName: "invoiceno",
        columnType: "text"

      },

      {
        displayName: "Invoice Date",
        columnName: "invoicedate",
        columnType: "date",
        format: "DD MMM yyyy"
      },

      {
        displayName: "Invoice Amount",
        columnName: "invoiceamount",
        columnType: "text"

      },
      {
        displayName: "Payment Date",
        columnName: "paymentdate",
        columnType: "date",
        format: "DD MMM yyyy"
      },
      {
        displayName: "Payment Amount",
        columnName: "paymentamount",
        columnType: "text"
        
      }

    ]

  }



 



  const clientPanelProps: PanelProps = {
    keyId: keyId,
    name: "Client Vacancy Details",
    description: "Update Client Vacancy Details",
    resetId: editClick,
    reseState: ()=>{setKeyId(-1)}

  }

  let formMetadata : Array<InputProps>= [
    {

        displayName: "Client",
        columnName: "name",
        id: "name",
        inpuType: "display",
        errorMessage: "Input is required",
        isRequired: true,
        isHtmlFormElement: true
    },

    {
        displayName: "Contact Name ",
        columnName: "contact",
       colSpan:2,
        id: "contact",
        inpuType: "text",
        errorMessage: "Input is required",
        isRequired: true,
        isHtmlFormElement: true
    },

    {
      displayName: "Designation ",
      columnName: "designation",
      id: "designation",
      inpuType: "text",
      errorMessage: "Input is required",
      isRequired: true,
      isHtmlFormElement: true
  },

  {
    displayName: "Depratment ",
    columnName: "department",
    id: "department",
    inpuType: "text",
    errorMessage: "Input is required",
    isRequired: true,
    isHtmlFormElement: true
},


    {
        displayName: "Mobile",
        columnName: "mobile",
        id: "mobile",
        inpuType: "text",
        errorMessage: "Input is required",
        isRequired: false,
        isHtmlFormElement: true
    },
    {
      displayName: "Office phone",
      columnName: "officephone",
      id: "officephone",
      inpuType: "text",
      errorMessage: "Input is required",
      isRequired: true,
      isHtmlFormElement: true
  },
    {
        displayName: "Email",
        columnName: "email",
        id: "email",
        inpuType: "email",
        errorMessage: "Input is required",
        isRequired: true,
        isHtmlFormElement: true
    },

    {
      displayName: "Sub Entity Details",
      columnName: "sec1",
      id: "sec1",
      colSpan:3,
      inpuType: "section",
      errorMessage: "Input is required",
      isRequired: false,
      isHtmlFormElement: true
  },


    {
      displayName: "Sub Entity  Name ",
      columnName: "subentity",
      id: "subentity",
      inpuType: "text",
      errorMessage: "Input is required",
      isRequired: false,
      isHtmlFormElement: true
  },


    {
      displayName: "Sub Entity Contact Person ",
      columnName: "subcontact",
      id: "subcontact",
      inpuType: "text",
      errorMessage: "Input is required",
      isRequired: false,
      isHtmlFormElement: true
  },

  {
    displayName: "Sub Entity Designation ",
    columnName: "subdesignation",
    id: "subdesignation",
    inpuType: "text",
    errorMessage: "Input is required",
    isRequired: false,
    isHtmlFormElement: true
},

{
  displayName: "Sub Entity Depratment ",
  columnName: "subdepartment",
  id: "subdepartment",
  inpuType: "text",
  errorMessage: "Input is required",
  isRequired: false,
  isHtmlFormElement: true
},


  {
      displayName: "Sub Entity Mobile",
      columnName: "submobile",
      id: "mobile",
      inpuType: "text",
      errorMessage: "Input is required",
      isRequired: false,
      isHtmlFormElement: true
  },
  {
    displayName: "Sub Entity Office phone",
    columnName: "subofficephone",
    id: "officephone",
    inpuType: "text",
    errorMessage: "Input is required",
    isRequired: false,
    isHtmlFormElement: true
},
  {
      displayName: "Sub Entity Email",
      columnName: "subemail",
      id: "email",
      inpuType: "email",
      errorMessage: "Input is required",
      isRequired: false,
      isHtmlFormElement: true
  },
   
    {

        displayName: "Position",
        columnName: "positionid",
        id: "positionid",
        inpuType: "combo",
        api: {
            url: apiURL("/api/Reference/GetPositions"),
            method: "get",
            mapResponse: {id: "id", name : "title", code:"code"}
        },
        errorMessage: "Input is required",
        isRequired: true,
        isHtmlFormElement: true
    },


    {

        displayName:"No of Vacancies",
        columnName: "noofvaccancies",
        id: "noofvaccancies",
        inpuType: "number",
        errorMessage: "Input is required",
        isRequired: true,
        isHtmlFormElement: true
    },


    {

      displayName:"Place of Work",
      columnName: "placeofwork",
      id: "placeofwork",
      inpuType: "text",
      errorMessage: "Input is required",
      isRequired: false,
      isHtmlFormElement: true
  },

  {

    displayName: "Experience",
    columnName: "experienceid",
    id: "experienceid",
    inpuType: "combo",
    api: {
        url: apiURL("/api/Reference/GetExperience"),
        method: "get",
        mapResponse: {id: "id", name : "name"}
    },
    errorMessage: "Input is required",
    isRequired: false,
    isHtmlFormElement: true
},


{

  displayName: "Qualification",
  columnName: "qualificationid",
  id: "qualificationid",
  inpuType: "combo",
  api: {
      url: apiURL("/api/Reference/GetQualification"),
      method: "get",
      mapResponse: {id: "id", name : "name"}
  },
  errorMessage: "Input is required",
  isRequired: false,
  isHtmlFormElement: true
},

{

  displayName: "Full Time/Part Time",
  columnName: "emplymenttypeid",
  id: "emplymenttypeid",
  inpuType: "combo",
  api: {
      url: apiURL("/api/Reference/GetEmploymentTypes"),
      method: "get",
      mapResponse: {id: "id", name : "name"}
  },
  errorMessage: "Input is required",
  isRequired: false,
  isHtmlFormElement: true
},

{

  displayName: "Salary Range",
  columnName: "salaryrangeid",
  id: "salaryrangeid",
  inpuType: "combo",
  api: {
      url: apiURL("/api/Reference/GetSalary"),
      method: "get",
      mapResponse: {id: "id", name : "salary"}
  },
  errorMessage: "Input is required",
  isRequired: false,
  isHtmlFormElement: true
},

{

  displayName: "Engagement Type",
  columnName: "deploymentid",
  id: "deploymentid",
  inpuType: "combo",
  api: {
      url: apiURL("/api/Reference/GetDeployments"),
      method: "get",
      mapResponse: {id: "id", name : "name"}
  },
  errorMessage: "Input is required",
  isRequired: false,
  isHtmlFormElement: true
},

{

  displayName:"Period of Engagement(in months)",
  columnName: "periodofengagement",
  id: "periodofengagement",
  inpuType: "number",
  errorMessage: "Input is required",
  isRequired: false,
  isHtmlFormElement: true
},

{

  displayName:"Working Hours Per Day",
  columnName: "workinghoursperday",
  id: "workinghoursperday",
  inpuType: "number",
  errorMessage: "Input is required",
  isRequired: false,
  isHtmlFormElement: true
},

{

  displayName:"Working Hours Per Week",
  columnName: "workinghoursperwek",
  id: "workinghoursperwek",
  inpuType: "number",
  errorMessage: "Input is required",
  isRequired: false,
  isHtmlFormElement: true
},
{

  displayName:"Working Days Per Week",
  columnName: "workingdaysperweek",
  id: "workingdaysperweek",
  inpuType: "text",
  errorMessage: "Input is required",
  isRequired: false,
  isHtmlFormElement: true
},

{

  displayName:"Probation Period",
  columnName: "probationperiod",
  id: "probationperiod",
  inpuType: "number",
  errorMessage: "Input is required",
  isRequired: false,
  isHtmlFormElement: true
},


{

  displayName:"Annual Leave(No of Days)",
  columnName: "annualleave",
  id: "annualleave",
  inpuType: "number",
  errorMessage: "Input is required",
  isRequired: false,
  isHtmlFormElement: true
},

{

  displayName:"Compassionate Leave(No of Days)",
  columnName: "compassionateleave",
  id: "compassionateleave",
  inpuType: "number",
  errorMessage: "Input is required",
  isRequired: false,
  isHtmlFormElement: true
},

{

  displayName:"Transport Allowance",
  columnName: "transportallowance",
  id: "transportallowance",
  inpuType: "text",
  errorMessage: "Input is required",
  isRequired: false,
  isHtmlFormElement: true
},

{

  displayName:"Medical Benefits",
  columnName: "medicalbenefits",
  id: "medicalbenefits",
  inpuType: "textarea",
  colSpan:3,
  rows: 4,
  cols: 50,
  errorMessage: "Input is required",
  isRequired: false,
  isHtmlFormElement: true
},



{
  displayName: "Start Date",
  columnName: "startdate",
  id: "startdate",
  inpuType: "date",
  errorMessage: "Input is required",
  isRequired: false,
  isHtmlFormElement: true
},

    {
      displayName: "Requested Date",
      columnName: "requesteddate",
      id: "requesteddate",
      inpuType: "date",
      errorMessage: "Input is required",
      isRequired: false,
      isHtmlFormElement: true
   },


    {
        displayName: "Closing Date",
        columnName: "closingdate",
        id: "closingdate",
        inpuType: "date",
        errorMessage: "Input is required",
        isRequired: false,
        isHtmlFormElement: true
    },
    {
      displayName: "Response Date",
      columnName: "responsedate",
      id: "responsedate",
      inpuType: "date",
      errorMessage: "Input is required",
      isRequired: false,
      isHtmlFormElement: true
  },

  {
    displayName: "Interview Period From",
    columnName: "interviewperiodfrom",
    id: "interviewperiodfrom",
    inpuType: "date",
    errorMessage: "Input is required",
    isRequired: false,
    isHtmlFormElement: true
},

{
  displayName: "Interview Period To",
  columnName: "interviewperiodto",
  id: "interviewperiodto",
  inpuType: "date",
  errorMessage: "Input is required",
  isRequired: false,
  isHtmlFormElement: true
},


  {
    displayName: "Remark by EHC",
    columnName: "remark",
    colSpan:3,
    id: "remark",
    inpuType: "textarea",
    rows: 4,
    cols: 50,
    errorMessage: "Input is required",
    isRequired: false,
    isHtmlFormElement: true
},

{
  displayName: "Remark by Client",
  columnName: "remarkbyclient",
  colSpan:3,
  id: "remarkbyclient",
  inpuType: "textarea",
  rows: 4,
  cols: 50,
  errorMessage: "Input is required",
  isRequired: false,
  isHtmlFormElement: true
},

{

  displayName:"Person-In-Charge(Educare)",
  columnName: "personincharge",
  id: "personincharge",
  inpuType: "text",
  errorMessage: "Input is required",
  isRequired: false,
  isHtmlFormElement: true
},

{
  displayName: "EHC Documents",
  columnName: "ehcuploads",
  id: "ehcuploads",
  inpuType: "file",
  errorMessage: "Input is required",
  isRequired: true,
  isHtmlFormElement: true
},

{
  displayName: "Client Documents",
  columnName: "clientuploads",
  id: "clientuploads",
  inpuType: "file",
  errorMessage: "Input is required",
  isRequired: true,
  isHtmlFormElement: true
},

{
  displayName: "Service Fee Chargeable by EHC",
  columnName: "sec2",
  id: "sec2",
  inpuType: "section",
  colSpan:3,
  errorMessage: "Input is required",
  isRequired: false,
  isHtmlFormElement: true
},



{

  displayName:"% of Monthly Salary or Lump Sum",
  columnName: "percentofmonthlysalary",
  id: "percentofmonthlysalary",
  inpuType: "number",
  step: "0.01",
  errorMessage: "Input is required",
  isRequired: false,
  isHtmlFormElement: true
},

{

  displayName:"% of Performance Bonus or Lump Sum",
  columnName: "percentofperformancebonus",
  id: "percentofperformancebonus",
  inpuType: "number",
  step: "0.01",
  errorMessage: "Input is required",
  isRequired: false,
  isHtmlFormElement: true
},

{
  displayName: "Report To Client HQ",
  columnName: "sec3",
  id: "sec3",
  inpuType: "section",
  colSpan:3,
  errorMessage: "Input is required",
  isRequired: false,
  isHtmlFormElement: true
},

{

  displayName:"Reported by",
  columnName: "reportedby",
  id: "reportedby",
  inpuType: "text",
  errorMessage: "Input is required",
  isRequired: false,
  isHtmlFormElement: true
},

{

  displayName:"Date of Report",
  columnName: "dateofreport",
  id: "dateofreport",
  inpuType: "date",
  errorMessage: "Input is required",
  isRequired: false,
  isHtmlFormElement: true
},

{

  displayName:"Reported To",
  columnName: "reportedto",
  id: "reportedto",
  inpuType: "text",
  errorMessage: "Input is required",
  isRequired: false,
  isHtmlFormElement: true
},

{
  displayName: "Payment from Client",
  columnName: "sec3",
  id: "sec3",
  inpuType: "section",
  colSpan:3,
  errorMessage: "Input is required",
  isRequired: false,
  isHtmlFormElement: true
},

{

  displayName:"Invoice No",
  columnName: "invoiceno",
  id: "invoiceno",
  inpuType: "text",
  errorMessage: "Input is required",
  isRequired: false,
  isHtmlFormElement: true
},

{

  displayName:"Invoice Date",
  columnName: "invoicedate",
  id: "invoicedate",
  inpuType: "date",
  errorMessage: "Input is required",
  isRequired: false,
  isHtmlFormElement: true
},

{

  displayName:"Invoice Amount",
  columnName: "invoiceamount",
  id: "invoiceamount",
  inpuType: "number",
  step: "0.01",
  errorMessage: "Input is required",
  isRequired: false,
  isHtmlFormElement: true
},


{

  displayName:"Payment Date",
  columnName: "paymentdate",
  id: "paymentdate",
  inpuType: "date",
  errorMessage: "Input is required",
  isRequired: false,
  isHtmlFormElement: true
},

{

  displayName:"Payment Amount",
  columnName: "paymentamount",
  id: "paymentamount",
  inpuType: "number",
  step: "0.01",
  errorMessage: "Input is required",
  isRequired: false,
  isHtmlFormElement: true
}


]

  const formObjectProps: FormProps={
    keyId: keyId,
    secondarykey : parseInt((document.getElementById("hdnClientId") as HTMLInputElement)?.value),
    formMetadata :  formMetadata,
    formType: 1,  //upload  0//for normal,
    submitApi: {
      url: apiURL("/api/Client/SaveClientVacancy"),
      method: "post",
      payload : {clientid: parseInt((document.getElementById("hdnClientId") as HTMLInputElement)?.value), id:keyId}
  }, 
    loadApi : keyId>0 ? {
      url: apiURL("/api/Client/GetClientVacancyList"),
      method: "post",
      payload : {clientvacancyid: keyId, clientid: parseInt((document.getElementById("hdnClientId") as HTMLInputElement)?.value)}
    } : {
      url: apiURL("/api/Client/GetClientList"),
      method: "post",
      payload : {clientid: parseInt((document.getElementById("hdnClientId") as HTMLInputElement)?.value)}
    } 
    
    ,
    cancel : editClick,
    refresh: doRefresh
  }





  let AdminSearchMetadata : Array<InputProps>= [
    

    {

        displayName: "Client",
        columnName: "clientid",
        id: "clientid",
        inpuType: "combo",
        api: {
            url: apiURL("/api/Client/GetClientList"),
            method: "post",
            payload: {clientid:0}
        },
        errorMessage: "Input is required",
        isRequired: false,
        isHtmlFormElement: true
    },


    {

        displayName: "Position",
        columnName: "positionid",
        id: "positionid",
        inpuType: "combo",
        api: {
            url: apiURL("/api/Reference/GetPositions"),
            method: "get",
            mapResponse : {name: "title", code : "code",  id: "id"}
        },
        errorMessage: "Input is required",
        isRequired: false,
        isHtmlFormElement: true
    },


    {

      displayName: "From date",
      columnName: "closingdate",
      id: "closingdate",
      inpuType: "date",
      errorMessage: "Input is required",
      isRequired: false,
      isHtmlFormElement: true
  },
  {

    displayName: "To date",
    columnName: "requesteddate",
    id: "requesteddate",
    inpuType: "date",
    errorMessage: "Input is required",
    isRequired: false,
    isHtmlFormElement: true
}



]

  const AdminSearchProps: FormProps={
    keyId: keyId,
    formMetadata :  AdminSearchMetadata,
    submitApi: {
      url: apiURL("/api/Client/GetClientVacancyList"),
      method: "post",
      payload : {clientid:0}
    },
    
    cancel : editClick,
    refresh: doRefresh,
    submitApiFormOtherComponent: submitApiFormOtherComponent
  }



  return (


    <div className="mx-4 my-4">
        <AdminSearch {...AdminSearchProps}></AdminSearch>
      <div className="sm:flex sm:items-center mx-6 my-4">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Client Vacancies</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the client Vacancy details
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button onClick={(event)=>editClick(event,0)}
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Add Client Vacancy
          </button>
        </div>
      </div>
      {clientTableProps? <GenricTable tableMetaDataProps={clientTableProps} refresh={refresh}></GenricTable> : ""}

      <input type="hidden" id="hdnClientId" value={newClientId} ></input>
      {keyId>=0 ? <GenricDialog   {...clientPanelProps}>
      <FormObject  {...formObjectProps}></FormObject>
      </GenricDialog> : <div></div>}

    </div>
  )

}

export default ClientVacancyList