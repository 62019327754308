/* This example requires Tailwind CSS v2.0+ */

import { ChevronRightIcon, DocumentIcon } from "@heroicons/react/outline";
import moment from "moment"
import { useEffect, useState } from "react";
import { getApiResults } from "../../../configs/appliationConfig";
import { getFormattedDate } from "../../../configs/utils";
import GenericLink from "../../atoms/GenericLink";
import GenricDialog, { PanelProps } from "../Dialog";

  const GenericList = (props: any) =>{
    const [itemList, setItemList] = useState<object[]>([]);
    const [content,setContent] =useState("");

    useEffect(() => {
      getApiResults(props.tableMetaDataProps.api).then(
        (res: any) => { setItemList(res.objectList) }
      )
    }, [props.tableMetaDataProps.api])
  
  
  
    useEffect(()=>{
  
      getApiResults(props.tableMetaDataProps.api).then(
        (res: any) => { setItemList(res.objectList) }
      )
    
    },[props.refresh])

    const positionJDPanelProps: PanelProps = {
      keyId: 0,
      name: "Job Description",
      description: "Update Position Details",
      resetId: ()=>{setContent("")},
      reseState: ()=>{setContent("")}
    
    }
  
    
    return(
        itemList?        <div className="px-4 sm:px-6 lg:px-8" >
           { content? <GenricDialog {...positionJDPanelProps}>
        <div  dangerouslySetInnerHTML={{__html: content}} />
        
    </GenricDialog> : ""   }     

        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
       
              <ul role="list" className="grid grid-cols-3 mx-5 my-5 gap-6 sm:grid-cols-2 lg:grid-cols-3 ">

  {itemList.map((item: any, index: number) => { return (
                 

                          props.tableMetaDataProps.columnConfigs.map((x: any) => {

                            return (
  
        <li key={item.id} className="col-span-3 bg-white rounded-lg shadow divide-y divide-gray-200 bg-sky-50">
          <div className="w-full flex items-center justify-between p-6 space-x-6">
            <div className="flex-1 truncate">
              <div className="flex items-center space-x-3">
                <h3 className="text-gray-900 text-sm font-medium truncate">
                               {index +1  + ".   "}
                                   {
                                        x.titleColumns.map((y: any)=>{
                                            return item[y] + " "
                                        })
                                    }
                </h3>
                <span className="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">
                  {item.groupname}
                </span>
              </div>
              
            </div>
         
          </div>
          <div>
            <div className="-mt-px flex divide-x divide-gray-200">
              <div className="w-0 flex-1 flex">
                 

                  <a
                
                  className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                >
                  <ChevronRightIcon className="w-5 h-5 text-gray-400 cursor-pointer" aria-hidden="true" onClick={()=>setContent(item[x.descriptionColumns])} />
                  <span  onClick={()=>setContent(item[x.descriptionColumns])}  className="ml-3 cursor-pointer">{x.viewLink}</span>
                </a> 
                        
              </div>

              <div className="-ml-px w-0 flex-1 flex">
                <div className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                >
              { x.editableLink ? <GenericLink func={(event: any)=>x.func(event,item[x.key])} displayText={x.editableLink}></GenericLink> : "" }
                                  { x.publishLink && !item["ispublish"] ? <div className="ml-3"><GenericLink func={(event: any)=>x.publishFunc(event,item[x.key], true)} displayText={x.publishLink}></GenericLink> </div>: 
                                   
                                   <div className="ml-3"><GenericLink func={(event: any)=>x.publishFunc(event,item[x.key], false)} displayText={"Un Publish"}></GenericLink></div>
                                   }
                                   </div>
              </div>
            </div>
          </div>
        </li>
            )

                } )


                    )})}

      </ul>

        </div>
          </div>
        </div>
      </div>
    </div> :<div></div>
    )
  }
  
  export default GenericList