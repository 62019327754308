import { createSlice } from '@reduxjs/toolkit'
import { User } from './models/user'

const user : User ={
  userid:"",
  token: ""
}

export const userSlice = createSlice({
  name: 'user',
  initialState: { user: user},
  reducers: {
    setToken: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.user=action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setToken } = userSlice.actions

export default userSlice.reducer