import { InputProps } from "../../../components/atoms/types/InputProps"
import GenricDialog from "../../../components/organisms/Dialog"
import FormObject from "../../../components/organisms/Form"
import GenricPanel, { PanelProps } from "../../../components/organisms/Panel"
import { FormProps } from "../../../components/organisms/types/FormProps"
import { apiURL } from "../../../configs/appliationConfig"




const NewInterviewPanel =(props: any)=>{
    const {keyId, editClick, doRefresh, applicantid} =props;
    const InterviewPanelProps: PanelProps = {
        keyId: keyId,
        name: "Interview",
        description: "Arrange Interview",
        resetId: editClick,
        reseState: ()=>{props.editClick(null, -1)}
    
      }
    
      let formMetadata : Array<InputProps>= [
    
    
        {
    
          displayName: "Applicant Name",
          columnName: "applicantname",
          id: "applicantname",
          inpuType: "display",
          colSpan:3,
          errorMessage: "Input is required",
          isRequired: false,
          isHtmlFormElement: true
        },
    
        {
    
          displayName: "Email address ",
          columnName: "emailaddress",
          id: "emailaddress",
          colSpan:3,
          inpuType: "display",
          errorMessage: "Input is required",
          isRequired: false,
          isHtmlFormElement: true
        },
    
        {
    
          displayName: "Mobile",
          columnName: "mobile",
          id: "mobile",
          inpuType: "display",
          errorMessage: "Input is required",
          isRequired: false,
          isHtmlFormElement: true
        },
    
        {
    
          displayName: "Client",
          columnName: "clientid",
          colSpan:3,
          id: "clientid",
          inpuType: "combo",
          api: {
            url: apiURL("/api/Reference/GetClients"),
            method: "get"
          },
          errorMessage: "Input is required",
          isRequired: true,
          isHtmlFormElement: true
        },
    
        {
    
          displayName: "Position",
          columnName: "positionid",
          colSpan:3,
          id: "positionid",
          inpuType: "combo",
          api: {
            url: apiURL("/api/Reference/GetPositions"),
            method: "get",
            mapResponse: {id: "id", name : "title", code:"code"}
          },
          errorMessage: "Input is required",
          isRequired: true,
          isHtmlFormElement: true
        },
    
        {
    
          displayName: "Status",
          columnName: "status",
          colSpan:3,
          id: "status",
          inpuType: "combo",
          api: {
            url: apiURL("/api/Reference/GetInterviewStatuses"),
            method: "get"
          },
          errorMessage: "Input is required",
          isRequired: true,
          isHtmlFormElement: true
        },
        
    
       
       
    
    
    
        {
          displayName: "Interview Date and Time",
          columnName: "interviewdate",
          id: "interviewdate",
          inpuType: "datetime-local",
          errorMessage: "Input is required",
          isRequired: false,
          isHtmlFormElement: true
       },
    
    
       {
    
        displayName:"Duration (minutes):",
        columnName: "duration",
        id: "duration",
        inpuType: "number",
        errorMessage: "Input is required",
        isRequired: false,
        isHtmlFormElement: true
    },
    
    
        {
            displayName: "CV Sent Date",
            columnName: "cvsentdate",
            id: "cvsentdate",
            inpuType: "date",
            errorMessage: "Input is required",
            isRequired: false,
            isHtmlFormElement: true
        },
    
        {
          displayName: "EHC  Confirm  Date",
          columnName: "clientconfirmdate",
          id: "clientconfirmdate",
          inpuType: "date",
          errorMessage: "Input is required",
          isRequired: false,
          isHtmlFormElement: true
      },
    
      {
        displayName: "Applicant  Confirm  Date",
        columnName: "applicantconfirmdate",
        id: "applicantconfirmdate",
        inpuType: "date",
        errorMessage: "Input is required",
        isRequired: false,
        isHtmlFormElement: true
    },
    
      {
        displayName: "Client  Confirm  Date",
        columnName: "ehcconfirmdate",
        id: "ehcconfirmdate",
        inpuType: "date",
        errorMessage: "Input is required",
        isRequired: false,
        isHtmlFormElement: true
    },
       
      {
        displayName: "Remark",
        columnName: "remarks",
        id: "remarks",
        inpuType: "textarea",
        rows:5,
        colSpan: 3,
        cols:100,
        errorMessage: "Input is required",
        isRequired: false,
        isHtmlFormElement: true
    },
    {
      displayName: "Reduced CV",
      columnName: "reducedCvs",
      id: "reducedCvs",
      inpuType: "file",
      errorMessage: "Input is required",
      isRequired: false,
      isHtmlFormElement: true
    }
    
    ]
    
      const formObjectProps: FormProps={
        keyId: keyId,
        secondarykey : applicantid,
        formType: 1,
        formMetadata :  formMetadata,
        submitApi: {
          url: apiURL("/api/Person/SaveInterview"),
          method: "post",
          payload : { id:keyId}
      }, 
        loadApi : {
          url: apiURL("/api/Person/GetApplicantInterview"),
          method: "post",
          payload : {id: keyId, applicantid:applicantid}
        } 
        
        ,
        cancel : editClick,
        refresh: doRefresh
      }


     return( 
       <div>
       {keyId>=0 ?  <GenricDialog   {...InterviewPanelProps}>
    
      <FormObject  {...formObjectProps}></FormObject>
     
      </GenricDialog> : <div></div>} 
      </div>
      
      )
    

}


export default NewInterviewPanel