import React, { Component, useEffect } from 'react';
import Draft, { EditorState, convertToRaw,Modifier } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { stateFromHTML } from 'draft-js-import-html'
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const  CustomRichTextBox =(props: any)=> {
   
    const [editorState, setEditorState] = React.useState(
        () => EditorState.createEmpty(),
      );

      var ContentState = Draft.ContentState;

      useEffect(()=>{
        if(editorState) { props.updateSelectedValue(props.inputProps.id,draftToHtml(convertToRaw(editorState.getCurrentContent())), props.inputProps.inpuType) }
      },[editorState])

      useEffect(()=>{
     
        if(props.selectedValue)
        {
          let contentState: any = stateFromHTML(props.selectedValue);
           setEditorState(EditorState.createWithContent(contentState))

        }
  
      },[props.selectedValue])


      const setValue = (editorState: any)=>{
        setEditorState(editorState)
      
      }
    
      return (<div>
        <label htmlFor={props.inputProps.inpuType} className="block text-sm font-medium text-gray-900">
          {props.inputProps.displayName}
        </label>
      <Editor
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={setValue}
      />
    
    </div>
  );
  
}

export default CustomRichTextBox





