import { useState } from "react"
import { InputProps } from "../../../components/atoms/types/InputProps"
import GenricDialog from "../../../components/organisms/Dialog"
import FormObject from "../../../components/organisms/Form"
import GenricPanel, { PanelProps } from "../../../components/organisms/Panel"
import GenricTable from "../../../components/organisms/Table/GenericTable"
import { FormProps } from "../../../components/organisms/types/FormProps"
import { apiURL } from "../../../configs/appliationConfig"


const ClientList = (props: any) => {

  const [keyId, setKeyId] = useState(-1)
  const [refresh, setRefresh] = useState(false)

  const editClick = (event: any , id: number) => {

    event.preventDefault();
    setKeyId(id)
  
  }

  const doRefresh =(ref: boolean)=>{
    setRefresh(ref)
  }

  const clientTableProps = {
    api: {

      url: apiURL("/api/Client/GetClientList"),
      method: "post",
      payload: {
        clientid: 0
      }
    },

    columnConfigs: [
      {
        key: "id",
        displayName: "Name",
        columnName: "name",
        columnType: "text"
      },

      {
     
        displayName: "Catgeory",
        columnName: "categoryname",
        columnType: "text"
      },

      // {
      //   displayName: "Contact Person",
      //   columnName: "contact",
      //   columnType: "text"

      // },

      // {
      //   displayName: "Mobile",
      //   columnName: "mobile",
      //   columnType: "text"

      // },
      // {
      //   displayName: "Email",
      //   columnName: "email",
      //   columnType: "text"

      // },
      // {
      //   displayName: "Requested Date",
      //   columnName: "requesteddate",
      //   columnType: "date",
      //   format: "DD MMM yyyy hh:mm a"
      // },

      {
        displayName: "Edit",
        columnName: "id",
        columnType: "hyperlink",
        func: editClick
      }

    ]

  }



  const clientPanelProps: PanelProps = {
    keyId: keyId,
    name: "Client Details",
    description: "Update Client Details",
    resetId: editClick,
    reseState: ()=>{setKeyId(-1)}

  }

  let formMetadata : Array<InputProps>= [
    {

        displayName: "Client",
        columnName: "name",
        id: "name",
        inpuType: "text",
        errorMessage: "Input is required",
        colSpan:2,
        isRequired: true,
        isHtmlFormElement: true
    },

    {

        displayName: "Category",
        columnName: "categoryid",
        id: "categoryid",
        inpuType: "combo",
        colSpan:3,
        api: {
            url: apiURL("/api/Reference/GetClientCategories"),
            method: "get"
        },
        errorMessage: "Input is required",
        isRequired: true,
        isHtmlFormElement: false,
    },

    // {
    //     displayName: "Contact",
    //     columnName: "contact",
    //     id: "contact",
    //     inpuType: "text",
    //     errorMessage: "Input is required",
    //     isRequired: true,
    //     isHtmlFormElement: true
    // },
    // {
    //     displayName: "Mobile",
    //     columnName: "mobile",
    //     id: "mobile",
    //     inpuType: "number",
    //     errorMessage: "Input is required",
    //     isRequired: true,
    //     isHtmlFormElement: true
    // },
    // {
    //     displayName: "Email",
    //     columnName: "email",
    //     id: "email",
    //     inpuType: "email",
    //     errorMessage: "Input is required",
    //     isRequired: true,
    //     isHtmlFormElement: true
    // },
    // {
    //     displayName: "Requested Date",
    //     columnName: "requesteddate",
    //     id: "requesteddate",
    //     inpuType: "date",
    //     errorMessage: "Input is required",
    //     isRequired: true,
    //     isHtmlFormElement: true
    // }
]

  const formObjectProps: FormProps={
    keyId: keyId,
    formMetadata :  formMetadata,
    submitApi: {
      url: apiURL("/api/Client/SaveClient"),
      method: "post",
      payload : {}
  }, 
    loadApi :  {
      url: apiURL("/api/Client/GetClientList"),
      method: "post",
      payload : {clientId: keyId}
    },
    cancel : editClick,
    refresh: doRefresh
  }

  return (


    <div className="mx-4 my-4">
      <div className="sm:flex sm:items-center mx-6 my-4">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Clients</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the clients details
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button onClick={(event)=>editClick(event,0)}
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Add Client
          </button>
        </div>
      </div>
      <GenricTable  tableMetaDataProps = {clientTableProps} refresh={refresh}>

      </GenricTable>
      {keyId>=0 ? <GenricDialog   {...clientPanelProps}>
      <FormObject  {...formObjectProps}></FormObject>
      </GenricDialog> : <div></div>}

    </div>
  )

}

export default ClientList