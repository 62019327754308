import { useEffect, useState } from "react";
import Navigation from "../../components/organisms/navigation";
import Table from "../../components/organisms/Table";
import { httpService } from "../../HttpService/httpService";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./admin.css"
import { domainURL } from "../../configs/appliationConfig";
import CustomComboBox from "../../components/atoms/CustomComboBox";
import { item } from "../../types/item";
import ExcelDownload from "../../components/organisms/Report/Excel";
import InterviewPanel from "./InteviewPanel";
import GenricPanel from "../../components/organisms/Panel";
import FormObject from "../../components/organisms/Form";
import NewInterviewPanel from "./Interview/NewInterviewPanel";


// const itemList = [
//   { name: 'Lindsay Walton', applyfrom: 'Front-end Developer',position1:'pos1', position2:'pos2', region: "test", mobile:'',
  
//   email: 'lindsay.walton@example.com'},
//   // More itemList...
// ]


const Admin=(props: any)=> {

  const [keyId, setKeyId] = useState(-1)
  const [refresh, setRefresh] = useState(false)
  const [itemList,setItemList] = useState([]);
  const [positions, setPositions] = useState<item[]>([])
  const [JobSoureces, setjobSources] = useState<item[]>([])
  const [salaries, setSalaries] = useState<item[]>([])
  const [nationality, setNationality] = useState<item[]>([])
  const [saved, setSaved] = useState(false)

  const formObjectInital: any={
   
    position: 0,
   
    salaryrange:0,

    nationality:0,

    age:"",
    experienceid:0,
    qualificationid:0,
    salary:0
    
   
   

}
const [formObject, setformObject] = useState(formObjectInital);

  var curr = new Date; // get current date


  var firstday = new Date(curr.setDate(-15)).toUTCString();
  var lastday = new Date().toUTCString();


  const [startDate, setStartDate] = useState(new Date(firstday));
  const [endDate, setEndDate] = useState(new Date(lastday));
  const [interviewApplicantId, setInterviewApplicantId] =useState({id:0, applicantid:0})
  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [salary, setSalary] = useState("");
  const [experience, setExperience] = useState<item[]>([]);
  const [qualification, setQualification] = useState<item[]>([]);


  let editClick = (event: any , id: number) => {
  
    if(event)
    {
    event.preventDefault();
    }
    setKeyId(id)
    setInterviewApplicantId({id:0, applicantid:0})
  
  }

 

  

  const doRefresh =(ref: boolean)=>{
    setRefresh(ref)
  }


  const handleChange= (event: any)=> {
    
     setName(event.target.value);
    
  }

  const handleChangeAge= (event: any)=> {
    
    setAge(event.target.value);
   
 }

 const handleChangeSalary= (event: any)=> {
    
  setSalary(event.target.value);
 
}

  const selectId =(id: any, applicantid: any)=>
  {
    
    setInterviewApplicantId({id:id, applicantid: applicantid})
  }

  const Search= () => {
    httpService.post(`${domainURL()}/api/Person/GetApplicantsList`, { startDate: startDate, endDate: endDate,
       name: name, position: formObject.position , salary:salary?salary:0, nationality:formObject.nationality,
      age: age, experienceid: formObject.experienceid, qualificationid: formObject.qualificationid,
      applyfrom: formObject.applyfrom
      
      }).then(
      res => setItemList(res.data.objectList)
    );
  }

  const updateValue=(key: string,value: string)=>{
    setSaved(false)
    formObject[key]=value
}

  const ClearSearch= () => {
    setStartDate(new Date(firstday))
    setEndDate(new Date(lastday))
    setSaved(true)
    setName("")
    setAge("")
    setSalary("")
    setformObject(formObjectInital)
    
  
  }

  useEffect(() => {
    httpService.get(`${domainURL()}/api/Reference/GetPositions`).then(
      res => {
        setPositions(res.data.objectList.map((x: any) => { return { id: x.id, name: x.code + " " + x.title } }))
      }
    )

    httpService.get(`${domainURL()}/api/Reference/GetSalary`).then(
      res => {
        setSalaries(res.data.objectList.map((x: any) => { return { id: x.id, name: x.salary } }))
      }


    )

    httpService.get(`${domainURL()}/api/Reference/GetNationalities`).then(
      res => {
        setNationality(res.data.objectList.map((x: any) => { return { id: x.id, name: x.name, orderno: x.orderno, type: x.type } }))
      }
    )

    httpService.get(`${domainURL()}/api/Reference/GetJobSources`).then(
      res => {
          setjobSources(res.data.objectList.map((x: any) => { return { id: x.id, name: x.source, sourcetypeid:x.sourcetypeid  } }))
      })

    httpService.get(`${domainURL()}/api/Reference/GetExperience`).then(
      res => {
        setExperience(res.data.objectList.map((x: any) => { return { id: x.id, name: x.name } }))
      }
    )

    httpService.get(`${domainURL()}/api/Reference/GetQualification`).then(
      res => {
        setQualification(res.data.objectList.map((x: any) => { return { id: x.id, name: x.name } }))
      }
    )

  },[])

  useEffect(()=>{
 
    Search();
    

    
  },[name,formObject])

    return (
      <div className="min-h-full">
         
          {/* {
         interviewApplicantId.applicantid>0?   <InterviewPanel interviewApplicantId={interviewApplicantId} resetId={setInterviewApplicantId}></InterviewPanel> : ""
} */}
{
   interviewApplicantId.applicantid>0?  <NewInterviewPanel keyId={interviewApplicantId.id} editClick={editClick} doRefresh={doRefresh} applicantid={interviewApplicantId.applicantid}></NewInterviewPanel> : <div></div>
        
}
           <header className="bg-white shadow">
            <div className="max-w-9xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold text-gray-900">Application List</h1>
            </div>
          </header>
          <main>
            
          <div className="max-w-9xl mx-auto py-2 sm:px-12 lg:px-12">
          <div className="grid grid-cols-12">
              <div className="col-span-3 sm:col-span-3 lg:col-span-2">
                  <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                    Applied From
                  </label>
                 
                  <DatePicker className="customDatePickerWidth" selected={startDate} onChange={(date:Date) => setStartDate(date)} />
              
                </div>

                <div className="col-span-3 sm:col-span-3 lg:col-span-2">
                  <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                   Applied  To
                  </label>
                  <DatePicker className="customDatePickerWidth" selected={endDate} onChange={(date:Date) => setEndDate(date)} />
                </div>
                
                <div className="col-span-8 sm:col-span-8 lg:col-span-4 md:ml-2">
                  <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                  Position
                  </label>
                  <CustomComboBox itemList={positions} saved={saved} error={""} formkey={"position"} updateValue={updateValue}>

                  </CustomComboBox>
                </div>



                <div className="col-span-6 sm:col-span-6 lg:col-span-1 md:ml-2">
                  <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                    Below Salary
                  </label>
                  <input
                    type="number"
                    name="salary"
                    onChange={handleChangeSalary}
                    id="salary"
                    value={salary}
                    autoComplete="salary"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>

                {/* <div className="col-span-8 sm:col-span-8 lg:col-span-2 md:ml-2">
                  <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                  Salary Range
                  </label>
                  <CustomComboBox itemList={salaries} saved={saved} error={""} formkey={"salaryrange"} updateValue={updateValue}>

                  </CustomComboBox>
                </div> */}
                
                <div className="col-span-6 sm:col-span-6 lg:col-span-2 md:ml-2">
                  <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                 Nationality
                  </label>
                  <CustomComboBox itemList={nationality} saved={saved} error={""} formkey={"nationality"} updateValue={updateValue}>

                                        </CustomComboBox>
                </div>

                </div>
                <div className="grid grid-cols-12 mt-3">

                <div className="col-span-6 sm:col-span-6 lg:col-span-2 md:ml-2">
                  <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                 Applied Form
                  </label>
                  <CustomComboBox itemList={JobSoureces} saved={saved} error={""} formkey={"applyfrom"} updateValue={updateValue}>

                                        </CustomComboBox>
                </div>

                <div className="col-span-6 sm:col-span-6 lg:col-span-2 md:ml-2">
                  <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                 Experience
                  </label>
                  <CustomComboBox itemList={experience} saved={saved} error={""} formkey={"experienceid"} updateValue={updateValue}>

                                        </CustomComboBox>
                </div>

                <div className="col-span-6 sm:col-span-6 lg:col-span-2 md:ml-2">
                  <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                 Qualification
                  </label>
                  <CustomComboBox itemList={qualification} saved={saved} error={""} formkey={"qualificationid"} updateValue={updateValue}>

                                        </CustomComboBox>
                </div>

                <div className="col-span-6 sm:col-span-6 lg:col-span-3 md:ml-2">
                  <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                    Applicant Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    onChange={handleChange}
                    id="name"
                    value={name}
                    autoComplete="name"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>


                <div className="col-span-6 sm:col-span-6 lg:col-span-1 md:ml-2">
                  <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                    Below Age
                  </label>
                  <input
                    type="number"
                    name="age"
                    onChange={handleChangeAge}
                    id="age"
                    value={age}
                    autoComplete="age"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>

              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <div className="mt-4 flex-shrink-0 flex md:mt-5 md:ml-4">
                  <button onClick={Search}
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Serach
                  </button>
                  <button onClick={ClearSearch}
                    type="button"
                    className="ml-3 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Clear
                  </button>

                  <ExcelDownload itemList={itemList}></ExcelDownload>

                </div>
              </div>

            </div>
          </div>

            <div className="max-w-9xl mx-auto py-6 sm:px-2 lg:px-8">
           { itemList? <Table  itemList={itemList}  selectId={selectId}></Table> : "" }
            </div>
          </main>
        </div>
    );
}

export default Admin