import { useEffect, useState } from "react"
import { InputProps } from "../../../components/atoms/types/InputProps"
import FormObject from "../../../components/organisms/Form"
import GenricPanel, { PanelProps } from "../../../components/organisms/Panel"
import AdminSearch from "../../../components/organisms/Search/AdminSearch"
import GenricTable from "../../../components/organisms/Table/GenericTable"
import { FormProps } from "../../../components/organisms/types/FormProps"
import { ApiRequestProps, apiURL } from "../../../configs/appliationConfig"
import { getFormattedDate } from "../../../configs/utils"
import NewInterviewPanel from "./NewInterviewPanel"


const NewInterviewList = (props: any) => {

  const [keyId, setKeyId] = useState(-1)
  const [applicantid, setApplicantId] = useState(0)
  const [refresh, setRefresh] = useState(false)
  
  const [clientTableProps, setInterviewTableProps] = useState<any>()

  var curr = new Date; // get current date
  var lastday = new Date(curr.setDate(+15)).toUTCString();
  var firstday = new Date().toUTCString();


  const [startDate, setStartDate] = useState(new Date(firstday));
  const [endDate, setEndDate] = useState(new Date(lastday));

  const submitApiFormOtherComponent= (apiReq: ApiRequestProps)=>
  {
   
    (interviewTable.api as ApiRequestProps) = apiReq
   // setApplicantId(parseInt((apiReq.payload as any).clientid))
    setInterviewTableProps(interviewTable)
  }

 

  let editClick = (event: any , id: number) => {
    
    if(event)
    {
    event.preventDefault();
    }
  
    setKeyId(id)
    
  
  }

 

  

  const doRefresh =(ref: boolean)=>{
    setRefresh(ref)
  }

  useEffect(()=>{
    setInterviewTableProps(interviewTable)
  },[])

  

   const interviewTable = {
    api: {

      url: apiURL("/api/Person/GetInterviewList"),
      method: "post",
      payload: { startDate: startDate, endDate: endDate}
       }
    ,
  

     columnConfigs: [
      {
        key: "id",
        displayName: "Name",
        columnName: "applicantname",
        columnType: "text"
      },

      {
        displayName: "Update Interview",
        columnName: "id",
        columnType: "hyperlink",
        func: editClick
      },


      {
     
        displayName: "Client",
        columnName: "clientname",
        columnType: "text"
      },

      {
        displayName: "Interview Date",
        columnName: "interviewdate",
        columnType: "date",
        format: "DD MMM yyyy hh:mm a"
      },

      {
     
        displayName: "Status",
        columnName: "status",
        columnType: "text"
      },

      {
     
        displayName: "Email Address",
        columnName: "emailaddress",
        columnType: "text"
      },

      {
     
        displayName: "Mobile",
        columnName: "mobile",
        columnType: "text"
      },


      {
        displayName: "Position",
        columnName: "position",
        columnType: "text"

      },


      {
        displayName: "Duration",
        columnName: "duration",
        columnType: "text"

      },


      {
        displayName: "CV Sent Date",
        columnName: "cvsentdate",
        columnType: "date",
        format: "DD MMM yyyy hh:mm a"
      },

      {
        displayName: "Remark",
        columnName: "remarks",
        columnType: "text"

      },
      {
        displayName: "Reduced CV",
        columnName: "reducedCvs",
        columnType: "files"
      }

      

      

    ]

   }



 



  




  let AdminSearchMetadata : Array<InputProps>= [
    

      {
          displayName: "Start Date",
          columnName: "startdate",
          id: "startdate",
          inpuType: "date",
          defaultValue: getFormattedDate(startDate.toDateString(), "YYYY-MM-DD"),
          errorMessage: "Input is required",
          isRequired: false,
          isHtmlFormElement: true
      },

      {
        displayName: "End Date",
        columnName: "enddate",
        id: "enddate",
        inpuType: "date",
        defaultValue: getFormattedDate(endDate.toDateString(), "YYYY-MM-DD"),
        errorMessage: "Input is required",
        isRequired: false,
        isHtmlFormElement: true
    },

    {

        displayName: "Client",
        columnName: "clientid",
        id: "clientid",
        inpuType: "combo",
        api: {
            url: apiURL("/api/Client/GetClientList"),
            method: "post",
            payload: {clientid:0}
        },
        errorMessage: "Input is required",
        isRequired: false,
        isHtmlFormElement: true
    },


    {

        displayName: "Position",
        columnName: "position",
        id: "position",
        inpuType: "combo",
        api: {
            url: apiURL("/api/Reference/GetPositions"),
            method: "get",
            mapResponse : {name: "title", code : "code",  id: "id"}
        },
        errorMessage: "Input is required",
        isRequired: false,
        isHtmlFormElement: true
    },
    {

      displayName: "Status",
      columnName: "status",
      id: "status",
      inpuType: "combo",
      api: {
          url: apiURL("/api/Reference/GetInterviewStatuses"),
          method: "get"
      },
      errorMessage: "Input is required",
      isRequired: false,
      isHtmlFormElement: true
  }




]

  const AdminSearchProps: FormProps={
    keyId: keyId,
    formMetadata :  AdminSearchMetadata,
    submitApi: {
      url: apiURL("/api/Person/GetInterviewList"),
      method: "post",
      payload: { startDate: startDate, endDate: endDate}
    },
    
    cancel : editClick,
    refresh: doRefresh,
    submitApiFormOtherComponent: submitApiFormOtherComponent
  }



  return (


    <div className="mx-4 my-4">
        <AdminSearch {...AdminSearchProps}></AdminSearch>
      <div className="sm:flex sm:items-center mx-6 my-4">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Follow up & Interview </h1>
          <p className="mt-2 text-sm text-gray-700">
           Interview Details
          </p>
        </div>
      
      </div>
      {clientTableProps? <GenricTable tableMetaDataProps={clientTableProps} refresh={refresh}></GenricTable> : ""}

      <input type="hidden" id="hdnApplicantId" value={applicantid} ></input>
      {keyId>0 ? <NewInterviewPanel keyId={keyId} editClick={editClick} doRefresh={doRefresh} applicantid={applicantid}></NewInterviewPanel> : <div></div>}

    </div>
  )

}

export default NewInterviewList