import { TrashIcon } from "@heroicons/react/outline";
import { is } from "immer/dist/internal";
import { useEffect, useState } from "react";
import { ApiRequestProps, apiURL, getApiResults } from "../../configs/appliationConfig";
import DeleteDialog from "../atoms/DeleteDialog";


const GenericFileUpload=(props: any)=> {
  const { displayName, columnName, inpuType, placeHolder, id, errorMessage,isRequired, defaultValue}  = props.inputProps ;
  const [value, setValue] =useState<any>()
  const [error, setError] =useState(false)
  const [isDelte, setIsDelete] =useState(false)
  const [delteId, setDeleteId] =useState(0)

  useEffect(()=>{
    setValue( props.selectedValue)

  },[
    props.selectedValue
  ])


  useEffect(()=>{
    if(isDelte)
    {
    
    const requestAPi : ApiRequestProps={
      url: apiURL("/api/fileupload/delete"),
      method: "post",
      payload : {id: delteId}

    }
    
    setDeleteId(0)
    setIsDelete(false);
    getApiResults(requestAPi).then((res: any)=>{

      
      value.splice(value.findIndex(({id}:any) => id == delteId), 1);
      setValue([...value])
      
    
      
    })  
  }
 
   

  },[isDelte])
  

    const onChange = (e: any) => {
     props.updateSelectedValue(props.inputProps.columnName,e.target.files, props.inputProps.inpuType);
    };
  
    return (
      <div>
        {
          delteId? <DeleteDialog setIsDelete={setIsDelete} setDeleteId={setDeleteId}></DeleteDialog> : ""
        }
      <div>
        <label htmlFor={inpuType} className="block text-sm font-medium text-gray-900">
          {displayName}
        </label>
        <div className="mt-1">
        <input type="file" multiple onChange={onChange} />
        </div>
            {
                error && props.clickSubmit?
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                        {errorMessage}
                    </p> : ""
                                            }
      </div>
      

        {
          
         value? 
          value.map((x: any)=>{
            return <div><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href={x.filetype} target="_blank">{x.filename}</a>
              <TrashIcon className="flex-shrink-0 h-5 w-5 cursor-pointer" onClick={()=>setDeleteId(x.id)} ></TrashIcon>
            </div>
          })
          
          : ""
          
        }

      </div>
      
    );
  }


  export  default GenericFileUpload