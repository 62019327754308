/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/

import { Combobox } from "@headlessui/react"
import { CheckIcon, SelectorIcon } from "@heroicons/react/outline"
import { useEffect, useState } from "react"
import CustomComboBox from "./components/atoms/CustomComboBox"
import MessageBox from "./components/atoms/MessageBox"
import { httpService } from "./HttpService/httpService"
import { item } from "./types/item"
import { FileUploader } from "react-drag-drop-files";
import { domainURL } from "./configs/appliationConfig"
import ehclogo from "./assets/ehclogo.png"
import DatePicker from "react-datepicker";
import Captcha from "./components/molecules/Captcha"


const JobSoureces = [
    { id: 1, name: 'Leslie Alexander' },
    // More users...
]

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}


const fileTypes = ["doc", "docx", "pdf","xls","xlsx"];

export default function ApplicationForm(props: any) {
    const [jobSourcesTypes, setjobSourcesTypes] = useState<item[]>([])
    const [JobSoureces, setjobSources] = useState<item[]>([])
    const [regions, setRegions] = useState<item[]>([])
    const [positions, setPositions] = useState<item[]>([])
    const [salaries, setSalaries] = useState<item[]>([])
    const [nationality, setNationality] = useState<item[]>([])
    const [employmenttype, setEmploymentType] = useState<item[]>([])
    const [dateofbirth, setDateOfBirth] = useState(null);
    const [experience, setExperience] = useState<item[]>([]);
    const [qualification, setQualification] = useState<item[]>([]);
    const [file, setFile] = useState("");
    const [selectedJobSourceType, setSelectedJobSourceType] = useState(1);
    const [availabilities, setAvailabilities] = useState<item[]>([]);
    const [healthconditions, setHealthConditions] = useState<item[]>([]);

    const formErrors: any={
        jobsourceType: null,
        personid: null,
        applyfrom : null,
        position: null,
        position2: null,
        name:null,
        region:null,
        addressregion:null,
        addresslocation:null,
        salaryrange:null,
        file: null,
        emailaddress:null,
        nric: null,
        dateofbith:null,
        nationality:null,
        employmenttype:null
       
       

    }
    const [error, setError] =useState(formErrors)
        const handleChange = (file: any) => {
            setFile(file);
        };
    
    const [saved, setSaved] = useState(false)
    const [exist, setExists] = useState(false)


    
    
    const formObjectInital: any={
        id:0,
        jobsourceType: 0,
        personid:"",
        applyfrom : 0,
        position: 0,
        position2: 0,
        name:"",
        region:"",
        address:"",
        addressregion:0,
        addresslocation:"",
        salaryrange:0,
        resumeext: "",
        emailaddress:"",
        nric: "",
        dateofbirth:"",
        nationality:0,
        employmenttype:0,
        experienceid:0,
        qualificationid:0,
        skills:"",
        avaliabilityid:0,
        healthconditionid:0,
        salary:0,
        salaryto:0,
        remarks:""

       
       

    }

    const [formObject, setformObject] = useState(formObjectInital);

    const setJobSourcesType=(event: any)=> {
        setSelectedJobSourceType(parseInt(event.target.value));
       
     }



    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setExists(false)
        setSaved(false)
        let formData = new FormData(event.currentTarget);
       
        formObject.jobsourceType = formData.get("jobSourceType");
        formObject.name= formData.get("name");
        formObject.emailaddress= formData.get("email");
        formObject.mobile= formData.get("mobile");
        formObject.addresslocation= formData.get("location");
        formObject.address= formData.get("address");
        formObject.nric= formData.get("nric");
        formObject.skills= formData.get("skills");
        formObject.salary= formData.get("salary")? parseInt(formData.get("salary") as string) : 0;
        formObject.salaryto= formData.get("salaryto")? parseInt(formData.get("salaryto") as string) : 0;
        formObject.remarks= formData.get("remarks");
        formObject.dateofbirth=dateofbirth

        // formErrors.jobsourceType= formObject.jobsourceType? "" : "border-red-300";
        formErrors.name= formObject.name? "" : "border-red-300";
        formErrors.position= formObject.position? 0 : "border-red-300";
        formErrors.position2= formObject.position2? 0 : "border-red-300";
        formErrors.name= formObject.name? "" : "border-red-300";
        formErrors.addressregion= formObject.addressregion? 0 : "border-red-300";
        formErrors.addresslocation= formObject.addresslocation? "" : "border-red-300";
        formErrors.emailaddress= formObject.emailaddress? "" : "border-red-300";
        formErrors.mobile= formObject.mobile? "" : "border-red-300";
        formErrors.file= file? "" : "border-red-300";
        formErrors.nationality= formObject.nationality? 0 : "border-red-300";
        formErrors.nric= formObject.nric? "" : "border-red-300";
        formErrors.dateofbirth= dateofbirth? "" : "border-red-300";
        formErrors.address=formObject.address? "" : "border-red-300";
        formErrors.employmenttype=formObject.employmenttype? "" : "border-red-300";
        formErrors.skills=formObject.skills? "" : "border-red-300";
        formErrors.experienceid=formObject.experienceid? 0: "border-red-300";
        formErrors.qualificationid=formObject.qualificationid? 0 : "border-red-300";
        formErrors.avaliabilityid=formObject.avaliabilityid? 0 : "border-red-300";
        formErrors.healthconditionid=formObject.healthconditionid? 0 : "border-red-300";
        formErrors.salary=formObject.salary? 0 : "border-red-300";
        formErrors.salaryto=formObject.salaryto? 0 : "border-red-300";




        setError(formErrors);
        let isValid=true;
        Object.keys(formErrors).forEach(key=>{
            if(formErrors[key]==="border-red-300"){
                isValid=false;
            }
        })

        if(isValid)
        {

        const formData1 = new FormData();

            const json = JSON.stringify(formObject);
            // const blob = new Blob([json], {
            // type: 'application/json'
            // });
            const data = new FormData();
            formData1.append("document", json);

		formData1.append('File', file);
        //formObject.file=formData1;
        
        httpService.post(`${domainURL()}/api/Person/submit`, formData1).then(
            res => {
                if(res.data.status)
                {
                setSaved(res.data.status);
                (document.getElementById("jobForm") as HTMLFormElement).reset();
                }
                else
                {
                    setExists(true)
                }

            }
        )
        }
       
      }

      const updateValue=(key: string,value: string)=>{
            formObject[key]=value
      }

      function isValidDate(d: any) {
        if (Object.prototype.toString.call(d) === "[object Date]") {
            // it is a date
            if (isNaN(d)) { // d.getTime() or d.valueOf() will also work
              return false;
            } else {
              return true
            }
          } else {
            return false;
          }
      }

      const handleOnBlur = ({ target: { value } }: any) => {
        const date = new Date(value);
        if (isValidDate(date)) {
            formErrors.dateofbirth= formObject.dateofbirth? "" : "border-red-300";
            setError(formErrors);
        } else {
            formErrors.dateofbirth= ""
        }
      };

    // const [query, setQuery] = useState('')
    // const [selectedjobSource, setSelectedjobSource] = useState()

    // const filteredJobSoureces =
    //   query === ''
    //     ? JobSoureces
    //     : JobSoureces.filter((jobSource: any) => {
    //         return jobSource.source.toLowerCase().includes(query.toLowerCase())
    //       })

    useEffect(() => {

        httpService.get(`${domainURL()}/api/Reference/GetJobSourceTypes`).then(
            res => {
                setjobSourcesTypes(res.data.objectList.map((x: any) => { return { id: x.id, name: x.source } }))
            }



        )

        httpService.get(`${domainURL()}/api/Reference/GetJobSources`).then(
            res => {
                setjobSources(res.data.objectList.map((x: any) => { return { id: x.id, name: x.source, sourcetypeid:x.sourcetypeid  } }))
            }



        )

        httpService.get(`${domainURL()}/api/Reference/GetRegions`).then(
            res => {
                setRegions(res.data.objectList.map((x: any) => { return { id: x.id, name: x.region } }))
            }
        )

        httpService.get(`${domainURL()}/api/Reference/GetPositions`).then(
            res => {
                setPositions(res.data.objectList.map((x: any) => { return { id: x.id, name:  x.code + " " + x.title  } }))
            }
        )

        httpService.get(`${domainURL()}/api/Reference/GetSalary`).then(
            res => {
                setSalaries(res.data.objectList.map((x: any) => { return { id: x.id, name: x.salary } }))
            }
        )

        httpService.get(`${domainURL()}/api/Reference/GetNationalities`).then(
            res => {
                setNationality(res.data.objectList.map((x: any) => { return { id: x.id, name: x.name, orderno: x.orderno, type:x.type } }))
            }
        )

        httpService.get(`${domainURL()}/api/Reference/GetEmploymentTypes`).then(
            res => {
                setEmploymentType(res.data.objectList.map((x: any) => { return { id: x.id, name: x.name } }))
            }
        )

        httpService.get(`${domainURL()}/api/Reference/GetExperience`).then(
            res => {
                setExperience(res.data.objectList.map((x: any) => { return { id: x.id, name: x.name } }))
            }
        )

        httpService.get(`${domainURL()}/api/Reference/GetQualification`).then(
            res => {
                setQualification(res.data.objectList.map((x: any) => { return { id: x.id, name: x.name } }))
            }
        )


        httpService.get(`${domainURL()}/api/Reference/GetRefData?type=availability`).then(
            res => {
                setAvailabilities(res.data.objectList.map((x: any) => { return { id: x.id, name: x.name } }))
            }
        )

        httpService.get(`${domainURL()}/api/Reference/GetRefData?type=healthcondition`).then(
            res => {
                setHealthConditions(res.data.objectList.map((x: any) => { return { id: x.id, name: x.name } }))
            }
        )






    }, [])
    return (
        <div className="max-w-full">
            {
                saved? <MessageBox message="Submitted Successfully"></MessageBox> : ""
               
            }
            {
                 exist? <MessageBox message="You have already applied for the same position before" ></MessageBox> : ""
            }

            <main>
            {/* <div className=" mx-auto px-4 sm:px-6 lg:px-8">
                <img src={ehclogo}></img>
                </div> */}

                <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">


                    <form id="jobForm"  className="space-y-8 divide-y divide-gray-200" action="#" method="POST" onSubmit={handleSubmit}>
                        <div className="space-y-8 divide-y divide-gray-200">
                            <div>
                                <div>
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">Job Application Form</h3>
                                    <p className="mt-1 text-sm text-gray-500">

                                    </p>
                                </div>
{/* 
                                <div className="mt-6 grid grid-cols-2 gap-y-6 gap-x-4 sm:grid-cols-2">
                                    <div className="sm:col-span-4">
                                        <fieldset className="mt-4">
                                            <legend className="sr-only">Notification method</legend>
                                            <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                                                {jobSourcesTypes ? jobSourcesTypes.map((jobSourcesType: any) => (
                                                    <div key={jobSourcesType.id} className="flex items-center">
                                                        <input
                                                            id={jobSourcesType.id}
                                                            name="jobSourceType"
                                                            type="radio"
                                                            value={jobSourcesType.id}
                                                            defaultChecked={jobSourcesType.id === 1}
                                                            onChange={setJobSourcesType}
                                                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                                        />
                                                        <label htmlFor={jobSourcesType.id} className="ml-3 block text-sm font-medium text-gray-700">
                                                            {jobSourcesType.name}
                                                        </label>
                                                    </div>
                                                )) : ""}
                                            </div>
                                        </fieldset>
                                    </div>
                                </div> */}

                               {/* { selectedJobSourceType>1? */}

                                <div className="mt-6 grid grid-cols-2 gap-y-6 gap-x-4 sm:grid-cols-6">
                                    <div className="sm:col-span-4">
                                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                            Apply From *:
                                        </label>

                                        <CustomComboBox itemList={JobSoureces} saved={saved} formkey={"applyfrom"} updateValue={updateValue}>

                                        </CustomComboBox>
                                    </div>
                                    </div>
                                {/* </div> :""

                               } */}


                                <div className="mt-6 grid grid-cols-2 gap-y-6 gap-x-4 sm:grid-cols-6">
                                    <div className="sm:col-span-4">
                                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                            Name (as on NRIC) *:
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                type="text"
                                                name="name"
                                                id="name"
                                                className={`shadow-sm focus:ring-indigo-500 ${error.name} focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md`}
                                                placeholder="Full Name"
                                               
                                            />
                                        </div>
                                        {
                                            error.name? 
                                        <p className="mt-2 text-sm text-red-600" id="email-error">
                                            Input is required.
                                        </p> : ""
                                        }
                                    </div>
                                </div>

                                <div className="mt-6 grid grid-cols-2 gap-y-6 gap-x-4 sm:grid-cols-6">
                                    <div className="sm:col-span-4">
                                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                            Email address *:
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                type="email"
                                                name="email"
                                                id="email"
                                                className={`shadow-sm focus:ring-indigo-500 ${error.emailaddress} focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md`}
                                                placeholder="Email Address"
                                            />
                                        </div>
                                        {
                                            error.emailaddress? 
                                        <p className="mt-2 text-sm text-red-600" id="email-error">
                                             Field is required.
                                        </p> : ""
                                        }
                                    </div>
                                </div>


                                <div className="mt-6 grid grid-cols-2 gap-y-6 gap-x-4 sm:grid-cols-6">
                                    <div className="sm:col-span-1">
                                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                            Mobile/Home #: *
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                type="text"
                                                name="mobile"
                                                id="mobile"
                                                maxLength={8}
                                                pattern="\d*" 
                                                title="Enter valid phone number"
                                                className={`shadow-sm focus:ring-indigo-500 ${error.mobile} focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md`}
                                                placeholder="Mobile/Home"
                                            />
                                        </div>
                                        {
                                            error.mobile? 
                                        <p className="mt-2 text-sm text-red-600" id="email-error">
                                             Field is required.
                                        </p> : ""
                                        }
                                    </div>
                                </div>


                                <div className="mt-6 grid grid-cols-2 gap-y-6 gap-x-4 sm:grid-cols-6">
                                    <div className="sm:col-span-2">
                                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                            NRIC (only last four digits , includinging alphabet) #: *
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                type="text"
                                                name="nric"
                                                id="nric"
                                                maxLength={4}
                                                pattern="\d\d\d\w"
                                                title="four digits only including alphabet"
                                                className={`shadow-sm focus:ring-indigo-500 ${error.nric} focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md`}
                                                placeholder="XXXZ"
                                            />
                                        </div>
                                        {
                                            error.nric? 
                                        <p className="mt-2 text-sm text-red-600" id="email-error">
                                             Field is required.
                                        </p> : ""
                                        }
                                    </div>
                                </div>

                                <div className="mt-6 grid grid-cols-2 gap-y-6 gap-x-4 sm:grid-cols-6">
                                    <div className="sm:col-span-2">
                                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                            Date of Birth (DD/MM/YYYY)#:
                                        </label>
                                        <div className="mt-1">
                                         <DatePicker selected={dateofbirth} onBlur={handleOnBlur}  showYearDropdown
                                          dateFormat="dd/MM/yyyy" openToDate={new Date("1993/09/28")} onChange={(date:any) => setDateOfBirth(date)} />
                                        </div>
                                        {
                                            error.dateofbirth? 
                                        <p className="mt-2 text-sm text-red-600" id="email-error">
                                            Invalid date format.
                                        </p> : ""
                                        }
                                    </div>
                                </div>


                                <div className="mt-6 grid grid-cols-2 gap-y-6 gap-x-4 sm:grid-cols-6">
                                    <div className="sm:col-span-2">
                                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                            Nationality *:
                                        </label>

                                        <CustomComboBox itemList={nationality} saved={saved} error={error.nationality} formkey={"nationality"} updateValue={updateValue}>

                                        </CustomComboBox>
                                    </div>
                                </div>


                                <div className="mt-6 grid grid-cols-2 gap-y-6 gap-x-4 sm:grid-cols-6">
                                    <div className="sm:col-span-2">
                                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                            Address: *
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                type="text"
                                                name="address"
                                                id="address"
                                                className={`shadow-sm ${error.address} focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md`}
                                                placeholder="Street Name"
                                            />
                                        </div>
                                        {
                                            error.address? 
                                        <p className="mt-2 text-sm text-red-600" id="email-error">
                                             Field is required.
                                        </p> : ""
                                        }
                                    </div>
                                </div>

                           

                                <div className="mt-6 grid grid-cols-2 gap-y-6 gap-x-4 sm:grid-cols-6">
                                    <div className="sm:col-span-2">
                                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                            Region *:
                                        </label>

                                        <CustomComboBox itemList={regions} saved={saved} error={error.addressregion} formkey={"addressregion"} updateValue={updateValue}>

                                        </CustomComboBox>
                                    </div>
                                </div>

                                <div className="mt-6 grid grid-cols-2 gap-y-6 gap-x-4 sm:grid-cols-6">
                                    <div className="sm:col-span-2">
                                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                            Postal Code: *
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                type="text"
                                                name="location"
                                                id="location"
                                                className={`shadow-sm ${error.addresslocation} focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md`}
                                                placeholder="Postal Code"
                                            />
                                        </div>
                                        {
                                            error.addresslocation? 
                                        <p className="mt-2 text-sm text-red-600" id="email-error">
                                             Field is required.
                                        </p> : ""
                                        }
                                    </div>
                                </div>


                                <div className="mt-6 grid grid-cols-2 gap-y-6 gap-x-4 sm:grid-cols-6">
                                    <div className="sm:col-span-2">
                                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                            Qualification *:
                                        </label>

                                        <CustomComboBox itemList={qualification} saved={saved} error={error.qualificationid} formkey={"qualificationid"} updateValue={updateValue}>

                                        </CustomComboBox>
                                    </div>
                                </div>

                                <div className="mt-6 grid grid-cols-2 gap-y-6 gap-x-4 sm:grid-cols-6">
                                    <div className="sm:col-span-2">
                                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                            Experience *:
                                        </label>

                                        <CustomComboBox itemList={experience} saved={saved} error={error.experienceid} formkey={"experienceid"} updateValue={updateValue}>

                                        </CustomComboBox>
                                    </div>
                                </div>

                                <div className="mt-6 grid grid-cols-2 gap-y-6 gap-x-4 sm:grid-cols-6">
                                    <div className="sm:col-span-2">
                                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                            Language skills *:
                                        </label>

                                        <textarea name="skills" rows={4} cols={50} maxLength={250}
                                            id="skills" className={`shadow-sm ${error.skills} focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md`}
                                            placeholder=""
                                        />
                                    </div>
                                    {
                                        error.skills ?
                                            <p className="mt-2 text-sm text-red-600" id="email-error">
                                                Field is required.
                                            </p> : ""
                                    }
                                </div>
                            


                                <div className="mt-6 grid grid-cols-2 gap-y-6 gap-x-4 sm:grid-cols-6">
                                    <div className="sm:col-span-2">
                                        <label className="block text-sm font-medium text-gray-700">
                                            Position (1st option) *:
                                        </label>

                                        <CustomComboBox itemList={positions} saved={saved} error={error.position} selectedValue={props.jobid} formkey={"position"} updateValue={updateValue}>

                                        </CustomComboBox>
                                    </div>
                                </div>


                                <div className="mt-6 grid grid-cols-2 gap-y-6 gap-x-4 sm:grid-cols-6">
                                    <div className="sm:col-span-2">
                                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                            Employment Type *:
                                        </label>

                                        <CustomComboBox itemList={employmenttype} saved={saved} formkey={"employmenttype"} updateValue={updateValue}>

                                        </CustomComboBox>
                                    </div>
                                    </div>


                                <div className="mt-6 grid grid-cols-2 gap-y-6 gap-x-4 sm:grid-cols-6">
                                    <div className="sm:col-span-2">
                                        <label className="block text-sm font-medium text-gray-700">
                                        Position (2nd option) * :
                                        </label>

                                        <CustomComboBox itemList={positions} saved={saved} error={error.position2} formkey={"position2"} updateValue={updateValue}>

                                        </CustomComboBox>
                                    </div>
                                </div>


                                <div className="mt-6 grid grid-cols-2 gap-y-6 gap-x-4 sm:grid-cols-6">
                                    <div className="sm:col-span-2">
                                        <label className="block text-sm font-medium text-gray-700">
                                        Salary Range *:
                                        </label>

                                        {/* <CustomComboBox itemList={salaries} saved={saved} error={error.salaryrange} formkey={"salaryrange"} updateValue={updateValue}> */}

                                        {/* </CustomComboBox> */}
                                        <div className="mt-1 w-full">
                                            <div className="float-left">
                                            <input
                                                type="number"
                                                name="salary"
                                                id="salary"
                                                className={`shadow-sm focus:ring-indigo-500 ${error.salary} focus:border-indigo-500 block w-5/6 sm:text-sm border-gray-300 rounded-md`}
                                                placeholder="Salary From"
                                               
                                            /> </div><div className="float-left">  <input
                                            type="number"
                                            name="salaryto"
                                            id="salaryto"
                                            className={`shadow-sm focus:ring-indigo-500 ${error.salaryto} focus:border-indigo-500 block w-5/6 sm:text-sm border-gray-300 rounded-md`}
                                            placeholder="Salary To"
                                           
                                        /> </div>
                                        </div>
                                        {
                                            (error.salary || error.salaryto)? 
                                        <p className="mt-2 text-sm text-red-600" id="email-error">
                                            Input is required.
                                        </p> : ""
                                        }
                                    </div>
                                </div>

                                <div className="mt-6 grid grid-cols-2 gap-y-6 gap-x-4 sm:grid-cols-6">
                                    <div className="sm:col-span-2">
                                        <label className="block text-sm font-medium text-gray-700">
                                        Availability  *:
                                        </label>

                                        <CustomComboBox itemList={availabilities} saved={saved} error={error.availabilities} formkey={"avaliabilityid"} updateValue={updateValue}> 

                                        </CustomComboBox>
                                       
                                    </div>
                                </div>

                                <div className="mt-6 grid grid-cols-2 gap-y-6 gap-x-4 sm:grid-cols-6">
                                    <div className="sm:col-span-4">
                                        <label className="block text-sm font-medium text-gray-700">
                                        Health Conditions  *:
                                        </label>

                                        <CustomComboBox itemList={healthconditions} saved={saved} error={error.healthconditions} formkey={"healthconditionid"} updateValue={updateValue}> 

                                        </CustomComboBox>
                                       
                                    </div>
                                </div>


                                <div className="mt-6 grid grid-cols-2 gap-y-6 gap-x-4 sm:grid-cols-6">
                                    <div className="sm:col-span-4">
                                        <label className="block text-sm font-medium text-gray-700">
                                        Remarks  :
                                        </label>

                         
                                        <div className="mt-1">
                                            <textarea
                                                
                                                name="remarks"
                                                id="remarks"
                                                rows={6}
                                                className={`shadow-sm focus:ring-indigo-500  focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md`}
                                                placeholder="Remarks"
                                               
                                            />
                                        </div>
                                        
                                    </div>
                                </div>

                                <div className="mt-6 grid grid-cols-2 gap-y-6 gap-x-4 sm:grid-cols-6">
                                    <div className="sm:col-span-2">
                                    
                                    <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                            Upload CV *
                                        </label>
                                    <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
                                    {
                                            error.file? 
                                        <p className="mt-2 text-sm text-red-600" id="email-error">
                                             Please upload the CV.
                                        </p> : ""
                                        }
 
                                </div>
                                </div>

                                
                <div className="mt-6 grid grid-cols-2 gap-y-6 gap-x-4 sm:grid-cols-6">
                
                <div className="sm:col-span-6">
                <h6><u>Declaration</u></h6>                               
                    <ol>
                        <li>I hereby give my consent for Educare Human Capital Pte Ltd("Educare") to obtain and verify information from/with source, as it deems appropriate for the assessment of my application for employment.</li>
                        <li>
                            I declare that the information in this application for employment and any sheets attached are true to the best of my knowledge and I have not wilfully suppressed any material fact.
                        </li><li>
                            I accept that if any information given in/with this application is in any way false or incorrect, I may disqualified from employment or dismissed from service.
                        </li>
                        <li>
                            I hereby confirm that by completing this application form, I am deemed to have:
                            <ol>
                                <li>
                                    Granted by consent to Educare, in accordance with the relevant provisions of the Personal Data Protection Act(2012) for collecting, disclosing and using of my personal
                                    data provided here in:
                                </li>
                                <li>
                                    Been notified by Educare that the personal data hereby provided is for purpose in connection with the facilitation of my employment with either Educare or any other potential employer
                                </li>
                            </ol>
                        </li>
                    </ol>

                </div>
            </div>


                    {/* <Captcha></Captcha> */}

                             


                            </div>
                        </div>





                        <div className="pt-5">
                            <div className="flex justify-end">
                                <button onClick={()=>props.reseState()}
                                    type="button"
                                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>

                </div>
            </main>
        </div>
    )
}
