import { json } from "stream/consumers";
import { httpService } from "../HttpService/httpService";
import URLS from "./urls.json"


export const domainURL = () => {

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return URLS.domainURL;
    } else {
        return URLS.prodURL;
    }

}
export const apiURL = (url: string) => {
    return domainURL() + url;

}

export type ApiRequestProps ={ url: string, method: string, payload?: object , stringPayLoad? : string, mapResponse? : any}

export const getApiResults = async (request: ApiRequestProps) => {
    if(request.stringPayLoad)
    {
        request.payload= JSON.parse(request.stringPayLoad)
    }
    let data: any
    switch (request.method) {
        case "get":
             await httpService.get(request.url).then(res => {
                data= res.data;
            })
            break;

        default:
          
             await httpService.post(request.url, request.payload?request.payload: {}).then(res => {
                data= res.data;
            })
            break;
    }

    if(data.objectList!==null && request.mapResponse)
    {
        let newObj = {...request.mapResponse}
         data.objectList =  data.objectList.map((x: any)=>{
            
           Object.keys(request.mapResponse as Object).forEach(key=> {
          
             let resColumnKey = request.mapResponse[key]
             newObj[key] = x[resColumnKey]
               
            }
            
            )

            if(newObj.code && newObj.name)
            {
                newObj.name =  (newObj.code + " " + newObj.name)
            }
            
         
            return {...newObj}
        })
       
    }

    return data;
    

}