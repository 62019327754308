
import { Label } from "@headlessui/react/dist/components/label/label";
import { useEffect, useState } from "react";
import {  ApiRequestProps, getApiResults } from "../../../configs/appliationConfig";
import { httpService } from "../../../HttpService/httpService";
import CustomRichTextBox from "../../atoms/CustomRichTextBox";
import GenricLabel from "../../atoms/GenericLabel";
import GenericComboBox from "../../atoms/GenricComBox";
import CustomTextArea from "../../atoms/TextArea";
import TextBox from "../../atoms/TextBox"
import {  InputProps } from "../../atoms/types/InputProps";
import GenericFileUpload from "../../molecules/FileUpload";
import { FormProps } from "../types/FormProps";





const FormObject = (props: FormProps) => {


const [formValues, setFormValues]: [any,any] = useState({})
const [fileValues, setFileValues]: [any,any] = useState({})

const [clickSubmit, setClickSubmit] = useState(false);

useEffect(()=>{
    props.refresh(false)
    if(props.keyId>0 || props.secondarykey)
    {
   
    getApiResults(props.loadApi as ApiRequestProps).then(
       (res: any) => { 
           
        if(res.objectList===null)
        {
        setFormValues(res.object)
        }
        else
        {
            setFormValues(res.objectList[0])
        }
     }
    )
    }
},[props.keyId,props.secondarykey])


const updateSelectedValue= (id : string, selecttedValue: any, inpuType: string)=>{
   
    switch (inpuType) {
        case "combo":
            formValues[id]= parseInt(selecttedValue)
            break;
        case "number":
                formValues[id]= selecttedValue? parseFloat(selecttedValue) :0
                break;

        case "file":
            fileValues[id]= selecttedValue;
            setFileValues(fileValues)
        break
    
        default:
            formValues[id]= selecttedValue
            break;
    
    }
   
    setFormValues(formValues)
    
}


    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

    
        setClickSubmit(true)
     
        let isSubmit =true;
       
         props.formMetadata.forEach((x: any) => {
            if(x.isRequired)
            {
              
                if(formValues[x.columnName]===""|| formValues[x.columnName]===0 || formValues[x.columnName]===null ||
                formValues[x.columnName]==="0001-01-01T00:00:00" ||  formValues[x.columnName]==="\u0000"
                )
                {
                   
                    isSubmit=false
                }
            }
            
        })
     
        if(isSubmit)
        {
           
         

            if(props.formType===1)

            {
               
                const formData1 = new FormData();

                const json = JSON.stringify({ ...formValues, ...props.submitApi.payload});

                const data = new FormData();
                formData1.append("formObject", json);
                Object.keys(fileValues).forEach(key=>{
                 
                    for(var x = 0; x < fileValues[key].length; x++) {
                    
                        formData1.append(key, fileValues[key].item(x));    
                    }
                })
               

                props.submitApi.payload= formData1
                getApiResults(props.submitApi).then(
                    (res: any) => { 
                        props.submitApi.payload={}
                        if(res.status) 
                        {
                     setFormValues({})
                     setClickSubmit(false)
                     props.cancel(event, -1)
                     props.refresh(true)
                        }
                        else
                        {
                          
                            alert("Record already exist with same key")
                        }

                    })
            }

            

            else
            {

         
            props.submitApi.payload= { ...formValues, ...props.submitApi.payload};

            getApiResults(props.submitApi).then(
                (res: any) => { 
                   if(res.status) 
                   {
                   
                setFormValues({})
                setClickSubmit(false)
                props.cancel(event, -1)
                props.refresh(true)
                   }
                   else
                   {
                    props.submitApi.payload={}
                       alert("Record already exist with same key")
                   }
              })

            }

              
            
        }
        else
        {
            alert('Please fill in all the required fields');
        }
       

       






    }



    return (
        <form id="formObject" className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl" action="#" method="POST" onSubmit={handleSubmit}>
            <div>
                <div className="mx-4 mb-4 grid grid-cols-3 gap-4">
                  
                    {
                        props.formMetadata.map((x: InputProps) => {
                            let element: any = null
                                switch (x.inpuType) {
                                    case "section":
                                        element=<div className="pb-5 border-b border-gray-200">
                                        <div className="-ml-2 -mt-2 flex flex-wrap items-baseline">
                                          <h3 className="ml-2 mt-2 text-lg leading-6 font-medium text-gray-900">{x.displayName}</h3>
                                        </div>
                                      </div>
                                    break; 

                                    case "combo":
                                        element= <GenericComboBox selectedValue={formValues[x.columnName]} clickSubmit= {clickSubmit} updateSelectedValue={updateSelectedValue}   inputProps={x}></GenericComboBox>
                                        break;
                                     case "display":
                                        element= <GenricLabel selectedValue={formValues[x.columnName]} clickSubmit= {clickSubmit} updateSelectedValue={updateSelectedValue}   inputProps={x}></GenricLabel>
                                            break;
                                     case "richtext":
                                        element= <CustomRichTextBox selectedValue={formValues[x.columnName]} clickSubmit= {clickSubmit} updateSelectedValue={updateSelectedValue}   inputProps={x}></CustomRichTextBox>
                                            break;
                                    case "textarea":
                                                element= <CustomTextArea selectedValue={formValues[x.columnName]} clickSubmit= {clickSubmit} updateSelectedValue={updateSelectedValue}   inputProps={x}></CustomTextArea>
                                                    break;
                                     case "file":
                                                element= <GenericFileUpload selectedValue={formValues[x.columnName]} clickSubmit= {clickSubmit} updateSelectedValue={updateSelectedValue}   inputProps={x}></GenericFileUpload>
                                                    break;
                                    default:
                                        element=<TextBox  selectedValue={formValues[x.columnName]} updateSelectedValue={updateSelectedValue}  clickSubmit= {clickSubmit}  inputProps={x}></TextBox>
                                        break;
                                }
                                
                                return <div className={"mb-4 col-span-" + (x.colSpan? x.colSpan.toString(): "1")}>
                                {
                                    element
                                }
                            </div>
                            
                        })}
                </div>
                <div className="flex flex-shrink-0 justify-end px-4 py-4">
                    <button onClick={(event)=>props.cancel(event, -1)}
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"

                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        Save
                    </button>
                </div>
            </div>
        </form>
    )


}


export default FormObject