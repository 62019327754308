import React from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom'
import './app.css';
import Login from './Login';
import Admin from './pages/Admin';
import ApplicationForm from './ApplicationForm';
import { useAuth } from './Auth/AuthContext';
import { AuthProvider } from './Auth/AuthProvider';
import { useSelector } from 'react-redux'
import { RootState } from './store';
import InterViewList from './pages/Admin/InterviewList';
import ClientList from './pages/Admin/Client/ClientList';
import Navigation from './components/organisms/navigation';
import ClientVacancyList from './pages/Admin/Client/ClientVacancyList';
import NewInterviewList from './pages/Admin/Interview/NewInterviewList';
import PositionList from './pages/Admin/JobPositions/PositionList';
import CustomJobList from './components/organisms/List/CustomJobList';


function App() {
  // return (
  //   <div className="min-h-full">
  //       <Navigation></Navigation>
  //        <header className="bg-white shadow">
  //         <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
  //           <h1 className="text-3xl font-bold text-gray-900">Application List</h1>
  //         </div>
  //       </header>
  //       <main>
  //         <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
  //           {/* Replace with your content */}
  //           <div className="px-4 py-6 sm:px-0">
  //             <div className="border-4 border-dashed border-gray-200 rounded-lg h-96" />
  //           </div>
  //           {/* /End replace */}
  //         </div>
  //       </main>
  //     </div>
  // );
  return (
    <AuthProvider>
      <React.Fragment>
     
     { CheckAuth()? <Navigation></Navigation> : ""}
      
        <Routes>
         
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<CustomJobList />} />
          <Route
            path="/admin"
            element={
              <RequireAuth>
                <Admin />
              </RequireAuth>
            } />

          <Route
            path="/interviewlist"
            element={
              <RequireAuth>
                <NewInterviewList />
              </RequireAuth>
            } />
          <Route
            path="/clientlist"
            element={
              <RequireAuth>
                <ClientList />
              </RequireAuth>
            } />

        <Route
            path="/clientvacancylist"
            element={
              <RequireAuth>
                <ClientVacancyList />
              </RequireAuth>
            } />

        <Route
            path="/positionlist"
            element={
              <RequireAuth>
                <PositionList />
              </RequireAuth>
            } />

        </Routes>

      </React.Fragment>
    </AuthProvider>

  );
}


// interface AuthContextType {
//   user: any;
//   signin: (user: string, callback: VoidFunction) => void;
//   signout: (callback: VoidFunction) => void;
// }

// let AuthContext = React.createContext<AuthContextType>(null!);

// function AuthProvider({ children }: { children: React.ReactNode }) {
//   let [user, setUser] = React.useState<any>(null);

//   let signin = (newUser: string, callback: VoidFunction) => {
//     return fakeAuthProvider.signin(() => {
//       setUser(newUser);
//       callback();
//     });
//   };

//   let signout = (callback: VoidFunction) => {
//     return fakeAuthProvider.signout(() => {
//       setUser(null);
//       callback();
//     });
//   };

//   let value = { user, signin, signout };

//   return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
// }

// function useAuth() {
//   return React.useContext(AuthContext);
// }

// function AuthStatus() {
//   let auth = useAuth();
//   let navigate = useNavigate();

//   if (!auth.user) {
//     return <p>You are not logged in.</p>;
//   }

//   return (
//     <p>
//       Welcome {auth.user}!{" "}
//       <button
//         onClick={() => {
//           auth.signout(() => navigate("/"));
//         }}
//       >
//         Sign out
//       </button>
//     </p>
//   );
// }

function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = useAuth();
  let location = useLocation();
  const token = useSelector((state: RootState) => state.userState.user.token)
  if (!token) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

function CheckAuth() {
  let auth = useAuth();
  let location = useLocation();
  const token = useSelector((state: RootState) => state.userState.user.token)
  if (!token) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return false;
  }
  else
  {
    return true;
  }

  
}
export default App;
