/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { useEffect, useState } from 'react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { Combobox } from '@headlessui/react'
import { item } from '../../types/item'

// const itemList = [
//   { id: 1, name: 'Leslie Alexander' },
//   // More users...
// ]

type Props= {
  itemList: item[]
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}



export default function CustomComboBox(props:any) {

  const { itemList } =props
  const [query, setQuery] = useState('')
  const [selecteditem, setSelecteditem]: [any,any] = useState()
  useEffect(()=>{
    if(selecteditem) { props.updateValue(props.formkey, selecteditem.id) }
  },[selecteditem])

  useEffect(()=>{ 
    setSelecteditem({id:0, name:''}) 
  
  },[props.saved])

  useEffect(()=>{
    if(itemList && props.selectedValue)
    {
      
      setSelecteditem (itemList.filter((x: any)=>x.id==props.selectedValue)[0])
    }
    else
    {
      setSelecteditem ( {id:0, name:""})
    }
  },[props.selectedValue, itemList])

  useEffect(()=>{ 
  

    if(props.selectedId && itemList){ 
      let selectedList = itemList.filter((x: any)=>x.id==props.selectedId)
      setSelecteditem( selectedList.length>0?selectedList[0]: {id:0, name:''}) }
  
  },[props.selectedId, itemList])

  const filtereditemList =
    query === ''
      ? itemList
      : itemList.filter((item: any) => {
          return item.name.toLowerCase().includes(query.toLowerCase())
        })

  return (
    <Combobox as="div" value={selecteditem} onChange={setSelecteditem}>
      
      <div className="relative mt-1">
        <Combobox.Input autoComplete={"off"}
          className={`w-full rounded-md border border-gray-300 ${props.error} bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm`}
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(item: any) => item?.name}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center  rounded-r-md px-2 focus:outline-none">
          <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {filtereditemList.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filtereditemList.map((item: any) => (
              <Combobox.Option
                key={item.id}
                value={item}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span className={classNames('block truncate', selected && 'font-semibold')}>{item.name}</span>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-indigo-600'
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
      {
                                            props.error?
                                            
                                        <p className="mt-2 text-sm text-red-600" id="email-error">
                                             Field is required.
                                        </p> : ""
                                        }
    </Combobox>
    
  )
}
