

import { useEffect, useState } from "react";
import { ApiRequestProps, getApiResults } from "../../../configs/appliationConfig";
import GenricLabel from "../../atoms/GenericLabel";
import GenericComboBox from "../../atoms/GenricComBox";
import TextBox from "../../atoms/TextBox"
import { InputProps } from "../../atoms/types/InputProps";
import { FormProps } from "../types/FormProps";





const AdminSearch = (props: FormProps) => {


    const [formValues, setFormValues]: [any, any] = useState({})
    const [clickSubmit, setClickSubmit] = useState(false);

    useEffect(() => {
        props.refresh(false)
        if (props.keyId > 0) {

            getApiResults(props.loadApi as ApiRequestProps).then(
                (res: any) => {
                    debugger
                    if(res.objectList===null)
                    {
                    setFormValues(res.object)
                    }
                    else
                    {
                        setFormValues(res.objectList[0])
                    }
                }
            )
        }
    }, [props.keyId])


    useEffect(()=>{
        if(Object.keys(formValues).length === 0)
        {
           
        (document.getElementById("btnSearch") as HTMLButtonElement).click()
        }
    },[
        formValues
    ])


    const updateSelectedValue = (id: string, selecttedValue: string, inpuType: string) => {
        switch (inpuType) {
            case "combo":
                formValues[id] = parseInt(selecttedValue)
                break;

            default:
                formValues[id] = selecttedValue
                break;

        }
       

        setFormValues(formValues)
        if(selecttedValue && inpuType=="combo")
        {
         (document.getElementById("btnSearch") as HTMLButtonElement).click()
        }

    }


    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();


        setClickSubmit(true)

        let isSubmit = true;
        props.formMetadata.forEach((x: any) => {
            if (x.isRequired) {

                if (formValues[x.columnName] === "" || formValues[x.columnName] === 0 || formValues[x.columnName] === null) {
                    isSubmit = false
                }
            }

        })

        if (isSubmit) {
            props.submitApi.payload = { ... props.submitApi.payload, ...formValues };

           if(props.submitApiFormOtherComponent)
           {
            props.submitApiFormOtherComponent(props.submitApi)
           }



        }









    }



    return (
        <form id="amdinSearch" className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl" action="#" method="POST" onSubmit={handleSubmit}>

            <div className="flex flex-row">
                {
                    props.formMetadata.map((x: InputProps) => {
                        let element: any = null
                        switch (x.inpuType) {
                            case "combo":
                                element = <GenericComboBox selectedValue={formValues[x.columnName]} clickSubmit={clickSubmit} updateSelectedValue={updateSelectedValue} inputProps={x}></GenericComboBox>
                                break;
                            case "display":
                                return <GenricLabel selectedValue={formValues[x.columnName]} clickSubmit={clickSubmit} updateSelectedValue={updateSelectedValue} inputProps={x}></GenricLabel>
                                break;

                            default:
                                element = <TextBox selectedValue={formValues[x.columnName]} updateSelectedValue={updateSelectedValue} clickSubmit={clickSubmit} inputProps={x}></TextBox>
                                break;
                        }

                        return <div className={"ml-4 mb-4 grow h-" + (x.colDivide? x.colDivide : "4")} >
                            {
                                element
                            }
                        </div>


                    })}
                <div className="basis-1/6"><div className="flex flex-shrink-0 divide-y mt-1 justify-end px-4 py-4">
                    <button id="btnSearch"
                        type="submit"
                        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        Search
                    </button>

                    <button onClick={(event) =>{ setFormValues({});     }}
                        type="button"
                        className="ml-4 rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"

                    >
                        Clear
                    </button></div>

                </div>
            </div>

        </form>
    )


}


export default AdminSearch