
const GenericLink=(props: any)=>{
    return  <a href="#" 
     onClick={props.func}
    className="text-indigo-600 hover:text-indigo-900">
   { props.displayText }<span className="sr-only">, </span>
  </a>

}

export default GenericLink