/* This example requires Tailwind CSS v2.0+ */
import { ChevronRightIcon, DocumentIcon } from '@heroicons/react/outline'
import { ChevronLeftIcon, MailIcon, PhoneIcon } from '@heroicons/react/solid'
import { useEffect, useState } from 'react';
import { ApiRequestProps, apiURL, getApiResults } from '../../../configs/appliationConfig';
import { InputProps } from '../../atoms/types/InputProps';
import AdminSearch from '../Search/AdminSearch';
import { FormProps } from '../types/FormProps';
import ehclogo from "../../../assets/ehclogo.png"
import GenricDialog, { PanelProps } from '../Dialog';
import ApplicationForm from '../../../ApplicationForm';
import GenricNewDialog from '../Dialog/GenericNewDialog';



export default function CustomJobList(props: any) {

    const [itemList, setItemList] = useState<object[]>([]);
    const [jd, setJd] =useState("")
    const [jobid, setJobId] =useState(-1)

    
    const [refresh, setRefresh] = useState(false)


    const doRefresh =(ref: boolean)=>{
      setRefresh(ref)
    }

    const api : ApiRequestProps ={
      url: apiURL("/api/Reference/GetPublishedPositions"),
      method: "post",
      payload: {ispublish: true}
    }

    useEffect(() => {
      getApiResults(api).then(
        (res: any) => { setItemList(res.objectList) }
      )
    }, [])

    const submitApiFormOtherComponent= (apiReq: ApiRequestProps)=>
    {
     
        getApiResults(apiReq).then(
            (res: any) => { setItemList(res.objectList) }
          )
    }
  



  let AdminSearchMetadata : Array<InputProps>= [
    


 
  {

    displayName: "Job Title",
    columnName: "title",
    id: "title",
    inpuType: "text",
    errorMessage: "Input is required",
    isRequired: false,
    colDivide: 6,
    isHtmlFormElement: true
}




]

const AdminSearchProps: any={
  keyId: 0,
  formMetadata :  AdminSearchMetadata,
  submitApi: {
    url: apiURL("/api/Reference/GetPublishedPositions"),
    method: "post",
    payload: {ispublish: true}
  },
  refresh: doRefresh,
 
  submitApiFormOtherComponent: submitApiFormOtherComponent
}

const positionPanelProps: PanelProps = {
  keyId: 0,
  name: "Job Description",
  description: "Update Position Details",
  resetId: ()=>{setJd("")},
  reseState: ()=>{setJd("")}

}

const positionJobApplicationPanelProps: PanelProps = {
  keyId: 0,
  name: "",
  description: "",
  resetId: ()=>{setJobId(-1)},
  reseState: ()=>{setJobId(-1)}

}

  return (

    <div>
     <div className="max-w-7xl mx-1 sm:px-6 mb-8 lg:px-8">
                <img src={ehclogo}></img>
                </div>

  <div className="flex flex-row">
 
  <div className="basis-1/2">
  <AdminSearch {...AdminSearchProps} > </AdminSearch>
  </div>
  <div className="basis-1/2">
  <button onClick={()=>{setJobId(0); }}
        type="button"
        className="float-right mr-6 mt-6 inline-flex items-center px-6 py-2 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Apply Job
      </button>
  </div>
</div>
   
   { jd? <GenricDialog {...positionPanelProps}>
        <div  dangerouslySetInnerHTML={{__html: jd}} />
        
    </GenricDialog> : ""
   }
  { jobid>=0? <GenricDialog {...positionJobApplicationPanelProps}>
        <ApplicationForm jobid={jobid} reseState={setJobId}></ApplicationForm>
        
    </GenricDialog> : ""
   }

    <ul role="list" className="grid grid-cols-3 mx-5 my-5 gap-6 sm:grid-cols-2 lg:grid-cols-3 ">
     {itemList.map((item: any, index: number) => { return (
        <li key={item.id} className="col-span-3 bg-white rounded-lg shadow divide-y divide-gray-200 bg-sky-50">
          <div className="w-full flex items-center justify-between p-6 space-x-6">
            <div className="flex-1 truncate">
              <div className="flex items-center space-x-3">
                <h3 className="text-gray-900 text-sm font-medium truncate">{index+1}. {item.code} {item.title}</h3>
                <span className="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">
                  {item.groupname}
                </span>
              </div>
              {/* <p className="mt-1 text-gray-500 text-sm truncate">{item.jd}</p> */}
            </div>
         
          </div>
          <div>
            <div className="-mt-px flex divide-x divide-gray-200">
              <div className="w-0 flex-1 flex">
                <a
                
                  className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                >
                  <ChevronRightIcon className="w-5 h-5 text-gray-400 cursor-pointer" aria-hidden="true" onClick={()=>{setJobId(item.id); }} />
                  <span  onClick={()=>{setJobId(item.id); }}  className="ml-3 cursor-pointer">Apply</span>
                </a>
              </div>
              <div className="-ml-px w-0 flex-1 flex">
                <a
                  
                  className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
                >
                  <DocumentIcon onClick={()=>{setJd(item.jd); }} className="w-5 h-5 text-gray-400 cursor-pointer" aria-hidden="true" />
                  <span className="ml-3 cursor-pointer" onClick={()=>{setJd(item.jd); }}>View Job Description</span>
                </a>
              </div>
            </div>
          </div>
        </li>
    )})}
    </ul>

    </div>
  )
}
