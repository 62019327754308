
import React from "react";
import { httpService } from "../HttpService/httpService";
import { User } from "../state/models/user";
import { setToken } from "../state/userSlice";
import {  useDispatch } from 'react-redux'
import { fakeAuthProvider } from "./auth";
import { AuthContext } from "./AuthContext";
import { domainURL } from "../configs/appliationConfig";



 
 export const  AuthProvider =  ({ children }: { children: React.ReactNode }) => {
   let [user, setUser] = React.useState<any>(null);
   const dispatch = useDispatch()
 
   let signin = (userid: string, password: string ,callback: VoidFunction) => {
     return fakeAuthProvider.signin( async() => {
       //setUser(newUser);
       const response = await httpService.post(`${domainURL()}/api/User/token`, {userid:userid,  password: password})
       if(response.data.object)
       {
       dispatch(setToken(response.data.object))
       callback();
       }
       else{
         alert("Invalid UserId or Password!")
       }
     });  
   };
 
   let signout = (callback: VoidFunction) => {
     return fakeAuthProvider.signout(() => {
       setUser(null);
       callback();
     });
   };
 
   let value = { user, signin, signout };
 
   return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
 }