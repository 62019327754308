/* This example requires Tailwind CSS v2.0+ */

import moment from "moment"

  const Table = (props: any) =>{
    const {itemList} = props

  
    
    return (
      <div style={{overflowX:"scroll"}}>
      <div className="px-4 sm:px-6 lg:px-8" >
        
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                       No
                      </th>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Name
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Applying From
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Applied Date
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Email 
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                       Mobile
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Position1
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Position 2
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Employment Type
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Region
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Nationality
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Salary Range
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Age
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Experience
                      </th>

                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Qualification
                      </th>

                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Availability
                      </th>

                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Health Condition
                      </th>

                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Remarks
                      </th>


                    
                     
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {itemList.map((item: any,index: number) => (
                      <tr key={item.id}>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{index+1}</td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {item.name}<br></br>
                     
                          { 
                          new Date(item.interviewdate)> new Date() ? <div className="italic text-xs text-blue-600/100">
                            { "Interview  on " + moment(item.interviewdate).format("DD MMM yyyy hh:mm a")}

                          </div> :
                          <button
                        type="button" onClick={()=>props.selectId(item.interviewid, item.id)}
                        className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                      Arrange Interview
                        </button>
                      }
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.source}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{moment(item.createdate).format("DD MMM yyyy")}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"><span style={{display:"block"}}>{item.emailaddress}</span><span style={{display:"block"}}>
                        <a href={item.downloadlink} rel="noopener noreferrer" target="_blank" download className="text-indigo-600 hover:text-indigo-900">
                            Download CV<span className="sr-only">, </span>
                          </a></span>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.mobile}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.position1}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.position2}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.employmenttype}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.region}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.nationality}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.salary}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.age}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.experience}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.qualification}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.availabilty}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.healthcondition}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.remarks}</td>
                        
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    )
  }
  
  export default Table