/* This example requires Tailwind CSS v2.0+ */

import moment from "moment"
import { useEffect, useState } from "react"
import { getApiResults } from "../../../configs/appliationConfig";
import { getFormattedDate } from "../../../configs/utils";
import GenericLink from "../../atoms/GenericLink";



const GenricTable = (props: any) => {

  const [itemList, setItemList] = useState<object[]>([]);

  useEffect(() => {
    getApiResults(props.tableMetaDataProps.api).then(
      (res: any) => { setItemList(res.objectList) }
    )
  }, [props.tableMetaDataProps.api])



  useEffect(()=>{

    getApiResults(props.tableMetaDataProps.api).then(
      (res: any) => { setItemList(res.objectList) }
    )
  
  },[props.refresh])


  return (
    itemList? <div style={{ overflowX: "scroll" }}>
      <div className="px-4 sm:px-6 lg:px-8" >

        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      No
                    </th>
                      {

                        props.tableMetaDataProps.columnConfigs.map((x: any) => {
                          return <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            {x.displayName}
                          </th>

                        })

                      }
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {itemList.map((item: any, index: number) => { return (
                      <tr key={item.id}>
                        <td className="px-3 py-4 text-sm text-gray-500">{index +1}</td>

                        {

                          props.tableMetaDataProps.columnConfigs.map((x: any) => {

                            let DisplayText ="";
                            let downloadElements: any =[];
                           
                            let func=x.func?x.func : ()=>{};
                            let hyperLink = null;
                            switch (x.columnType) {
                              case "text":
                                DisplayText = item[x.columnName]
                                break;
                              case "date":
                                DisplayText = getFormattedDate(item[x.columnName], x.format)
                                break;
                            case "hyperlink":
                                DisplayText="";
                                hyperLink= <GenericLink func={(event: any)=>x.func(event,item[x.columnName])} displayText={x.displayName}></GenericLink>
                                break;
                              case "files":
                               
                                item[x.columnName].forEach((x: any)=>{
                                  downloadElements.push(  <div><a href={x.filetype} className="text-blue-600 visited:text-purple-600 cursor-pointer" target="_balnk"> {x.filename} </a></div>);
                                })
                                break;
                              default:
                                break;
                            }
                            return (
                              <td className="px-3 py-4 text-sm text-gray-500">{DisplayText}
                              {hyperLink? hyperLink : ""}
                               {downloadElements.length>0? 
                                downloadElements.map((x: any)=> {return x;})
                               :"" }
                              
                              </td>)

                          })

                        }




                      </tr>
                    )})}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> :<div></div>
  )
}

export default GenricTable