import React from "react";

interface AuthContextType {
    user: any;
    signin: (user: string,password: string , callback: VoidFunction) => void;
    signout: (callback: VoidFunction) => void;
  }
  
  export let AuthContext = React.createContext<AuthContextType>(null!);
 
   
  export function useAuth() {
     return React.useContext(AuthContext);
   }