
import axios from "axios";


// eslint-disable-next-line no-underscore-dangle
const _axios = axios.create();

const configs = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
 //   Authorization: `Bearer ${AuthService.getToken()}`
  },
  params: {}
};

export const HttpServiceConfigure = () => {
  _axios.interceptors.request.use((config) => {
    // eslint-disable-next-line no-param-reassign
    config = { ...configs, ...config };
    // if (AuthService.isLoggedIn()) {
    //   // @ts-ignore
    //   if (!config.url?.toLowerCase().includes(POLLING_URL)) {
    //     if (config.url.includes("version") === true) {
    //       const indexResult = config.url.indexOf("version");

    //       const apiVersion = config.url.substr(indexResult, config.url.length - 1);

    //       const updatedPaths = config.url.slice(0, indexResult - 1);
    //       // eslint-disable-next-line no-param-reassign
    //       config.url = updatedPaths;
    //       // eslint-disable-next-line no-param-reassign
    //       config.headers["X-Api-Version"] = apiVersion.substr(8);
    //     }

    //     const cb = () => {
    //       // eslint-disable-next-line no-param-reassign
    //       config.headers.Authorization = `Bearer ${AuthService.getToken()}`;
    //       return Promise.resolve(config);
    //     };
    //     return AuthService.updateToken(cb);
    //   }
    //   // eslint-disable-next-line no-param-reassign
    //   config.headers?.Authorization = `Bearer ${AuthService.getToken()}`;
       return Promise.resolve(config);
    // }
   // return {};
  });

  _axios.interceptors.response.use(handleSuccess, handleError);
};

const handleSuccess = (response: any) => response;

const handleError = (error: any) => Promise.reject(error);





HttpServiceConfigure();
const getAxiosClient = () => _axios;
export const httpService = {
  get : (path: string) => getAxiosClient().get(path, configs),

  getWithParam : (path: string, queries?: object) => {
    const tempConfigs = { ...configs };
    if (queries && Object.keys(queries).length > 0) {
      tempConfigs.params = queries;
    }
    return getAxiosClient().get(path, tempConfigs)
  },  
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  post : (path: string, payload: object, callback?: any) => getAxiosClient().post(path, payload, configs),

  put : (path: string, payload: object) => getAxiosClient().put(path, payload, configs),

  patch : (path: string, payload: object) => getAxiosClient().patch(path, payload, configs),

  delete: (path: string) => getAxiosClient().delete(path, configs)
  
};


