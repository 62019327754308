/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, XIcon } from '@heroicons/react/outline'
import { JsxElement } from 'typescript'

export type PanelProps = {
    name : string
    keyId: number
    resetId : (event: any, id: number, secondarkey: number)=>void
    reseState? : ()=>void
    description: string 
    children?: JSX.Element
}

export default function GenricDialog(props: PanelProps) {
  const [open, setOpen] = useState(true)
  const cancelButtonRef = useRef(null)


  useEffect(()=>{
    if(props.keyId>=0)
    {
    setOpen(true)
    }
  },[props.keyId])



  return(
    <Transition.Root show={open} as={Fragment}>
    <Dialog as="div" className="fixed inset-0 overflow-y-scroll" initialFocus={cancelButtonRef} onClose={setOpen}>
      <div className=" justify-center  pt-4 px-4 pb-20 max-w-full text-center sm:block sm:p-0">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="hidden sm:inline-block sm:align-middle " aria-hidden="true">
          &#8203;
        </span>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:max-w-7xl sm:p-6">
            <div>
            <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => { setOpen(false); (props.reseState as ()=>void)()}}>
                
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-3 text-left sm:mt-5">
                <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                {props.name}
                </Dialog.Title>
                <div className="mt-2 ">
                {props.children}
                </div>
              </div>
            </div>
            {/* <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                onClick={() => setOpen(false)}
              >
                Deactivate
              </button>
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                onClick={(event) => { setOpen(false) 
                  props.resetId(event,-1,0)
                }}
                // ref={cancelButtonRef}
              >
                Cancel
              </button>
            </div> */}
          </div>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition.Root>
  )

 
}
