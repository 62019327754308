import { useEffect, useState } from "react"
import { InputProps } from "../../../components/atoms/types/InputProps"
import GenricDialog from "../../../components/organisms/Dialog"
import FormObject from "../../../components/organisms/Form"
import GenericList from "../../../components/organisms/List"
import GenricPanel, { PanelProps } from "../../../components/organisms/Panel"
import AdminSearch from "../../../components/organisms/Search/AdminSearch"
import GenricTable from "../../../components/organisms/Table/GenericTable"
import { FormProps } from "../../../components/organisms/types/FormProps"
import { ApiRequestProps, apiURL, getApiResults } from "../../../configs/appliationConfig"


const PositionList = (props: any) => {

  const [keyId, setKeyId] = useState(-1)
  const [refresh, setRefresh] = useState(false)
  const [positionTableProps, setPositionTableProps] = useState<any>()
  const [jd, setJd] =useState("")

  const publishClick = (event: any , id: number, ispublish: boolean) => {

     event.preventDefault();
    // setKeyId(id)

    const   api=  {

      url: apiURL("/api/Reference/PublishPosition"),
      method: "post",
      payload: {
        id: id,
        ispublish: ispublish
      }

     
      
    }
    getApiResults(api).then(res=>{
      setRefresh(true)
      setRefresh(false)
    })
  
  }

  const editClick = (event: any , id: number) => {

    event.preventDefault();
    setKeyId(id)
  
  }

   

  const doRefresh =(ref: boolean)=>{
    setRefresh(ref)
  }

  const submitApiFormOtherComponent= (apiReq: ApiRequestProps)=>
  {
   
    (positionTable.api as ApiRequestProps) = apiReq
    setPositionTableProps(positionTable)
  }

  useEffect(()=>{
    setPositionTableProps(positionTable)
  },[])

  const positionTable = {
    api: {

      url: apiURL("/api/Reference/GetPositions"),
      method: "get"
    },

    columnConfigs: [
      {
        key: "id",
        displayName: "Position",
        titleColumns: ["code","title"],
        descriptionColumns:  "jd",
        editableLink: "Edit",
        publishLink: "Publish",
        viewLink: "View Job Description",
        func: editClick,
        publishColumn: "ispublish",
        publishFunc: publishClick,
        columnType: "text"
      },

   
    ]

  }



  const positionPanelProps: PanelProps = {
    keyId: keyId,
    name: "Position Details",
    description: "Update Position Details",
    resetId: editClick,
    reseState: ()=>{setKeyId(-1)}

  }

  let formMetadata : Array<InputProps>= [

    {

        displayName: "Code",
        columnName: "code",
        
        id: "code",
        inpuType: "text",
        errorMessage: "Input is required",
        isRequired: true,
        isHtmlFormElement: true
    },

    {

        displayName: "Position",
        columnName: "title",
        id: "title",
        colSpan:2,
        inpuType: "text",
        errorMessage: "Input is required",
        isRequired: true,
        isHtmlFormElement: true
    },

    {

        displayName: "Group",
        columnName: "groupid",
        id: "groupid",
        inpuType: "combo",
        api: {
            url: apiURL("/api/Reference/GetPositionGroups"),
            method: "get",
            mapResponse : {name: "group",   id: "id"}
        },
        errorMessage: "Input is required",
        isRequired: true,
        isHtmlFormElement: false,
    },

    {

        displayName: "Job Description",
        columnName: "jd",
        colSpan:3,
        id: "jd",
        inpuType: "richtext",
        errorMessage: "Input is required",
        isRequired: true,
        isHtmlFormElement: true
    }

]

  const formObjectProps: FormProps={
    keyId: keyId,
    formMetadata :  formMetadata,
    submitApi: {
      url: apiURL("/api/Reference/SavePosition"),
      method: "post",
      payload : {}
  }, 
    loadApi :  {
      url: apiURL("/api/Reference/GetPositionById"),
      method: "post",
      payload : {id:keyId}
    },
    cancel : editClick,
    refresh: doRefresh
  }



  let AdminSearchMetadata : Array<InputProps>= [
    

 


    {

        displayName: "Job Group",
        columnName: "groupid",
        id: "groupid",
        inpuType: "combo",
        colDivide: 4,
        api: {
            url: apiURL("/api/Reference/GetPositionGroups"),
            method: "get",
            mapResponse : {name: "group",   id: "id"}
        },
        errorMessage: "Input is required",
        isRequired: false,
        isHtmlFormElement: true
    }



]

  const AdminSearchProps: FormProps={
    keyId: keyId,
    formMetadata :  AdminSearchMetadata,
    submitApi: {
      url: apiURL("/api/Reference/GetPositionlist"),
      method: "post",
      payload : {groupid:0}
    },
    
    cancel : editClick,
    refresh: doRefresh,
    submitApiFormOtherComponent: submitApiFormOtherComponent
  }

  

  return (


    <div className="mx-4 my-4">
         <AdminSearch {...AdminSearchProps}></AdminSearch>
      <div className="sm:flex sm:items-center mx-6 my-4">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Positions</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all Job Positions
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button onClick={(event)=>editClick(event,0)}
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Add Job Position
          </button>
        </div>
      </div>
      {positionTableProps? <GenericList tableMetaDataProps={positionTableProps} refresh={refresh}></GenericList> : ""}
      {keyId>=0 ? <GenricDialog   {...positionPanelProps}>
      <FormObject  {...formObjectProps}></FormObject>
      </GenricDialog> : <div></div>}

     
  

    </div>
  )

}

export default PositionList