
import React, { useEffect, useState } from "react";
//@ts-ignore
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;





const  ExcelDownload = (props: any)=> {

    const [multiDataSet, setMultiDataSet] =useState(null)

    let styledMultiDataSet: any=null

    useEffect(()=>{     styledMultiDataSet = [
        {
          columns: [
            {
              value: "Headings",
              widthPx: 160,
              style: { font: { sz: "24", bold: true } },
            },
            {
              value: "Text Style",
              widthPx: 180,
              style: { font: { sz: "24", bold: true } },
            },
            {
              value: "Colors",
              style: { font: { sz: "24", bold: true } }, // if no width set, default excel column width will be used ( 64px )
            },
          ],
          data: [
            [
              { value: "H1", style: { font: { sz: "24", bold: true } } },
              { value: "Bold", style: { font: { bold: true } } },
              {
                value: "Red",
                style: {
                  fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
                },
              },
            ],
            [
              { value: "H2", style: { font: { sz: "18", bold: true } } },
              { value: "underline", style: { font: { underline: true } } },
              {
                value: "Blue",
                style: {
                  fill: { patternType: "solid", fgColor: { rgb: "FF0000FF" } },
                },
              },
            ],
            [
              { value: "H3", style: { font: { sz: "14", bold: true } } },
              { value: "italic", style: { font: { italic: true } } },
              {
                value: "Green",
                style: {
                  fill: { patternType: "solid", fgColor: { rgb: "FF00FF00" } },
                },
              },
            ],
            [
              { value: "H4", style: { font: { sz: "12", bold: true } } },
              { value: "strike", style: { font: { strike: true } } },
              {
                value: "Orange",
                style: {
                  fill: { patternType: "solid", fgColor: { rgb: "FFF86B00" } },
                },
              },
            ],
            [
              { value: "H5", style: { font: { sz: "10.5", bold: true } } },
              { value: "outline", style: { font: { outline: true } } },
              {
                value: "Yellow",
                style: {
                  fill: { patternType: "solid", fgColor: { rgb: "FFFFFF00" } },
                },
              },
            ],
            [
              { value: "H6", style: { font: { sz: "7.5", bold: true } } },
              { value: "shadow", style: { font: { shadow: true } } },
              {
                value: "Light Blue",
                style: {
                  fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
                },
              },
            ],
          ],
        },
      ];
// const newitemList: any = []
// props.itemList.forEach((x: any)=>{
//     newitemList.push(
//        [ {value: x.source,style: { font: { sz: "24", bold: true } } } ,
//         {value: x.createdate,style: { font: { sz: "24", bold: true } } } ,
//         {value: x.position1,style: { font: { sz: "24", bold: true } } } ,
//         {value: x.position2,style: { font: { sz: "24", bold: true } } } ,
//         {value: x.region,style: { font: { sz: "24", bold: true } } } ,
//         {value: x.nationality,style: { font: { sz: "24", bold: true } } } ,
//         {value: x.salary,style: { font: { sz: "24", bold: true } } } ,
//         {value: x.emailaddress,style: { font: { sz: "24", bold: true } } } ,
//         {value: x.mobile,style: { font: { sz: "24", bold: true } } } ,
//     ]
//     )
// })

//  const multiDataSet=  [ {
//       columns:[{value:"Job Source",  widthPx: 160,
//       style: { font: { sz: "24", bold: true } },},
//       {value:"Created Date", widthPx: 160,
//       style: { font: { sz: "24", bold: true } },},
//       {value:"Position1", widthPx: 160,
//       style: { font: { sz: "24", bold: true } },},
//       {value:"Position2", widthPx: 160,
//       style: { font: { sz: "24", bold: true } },},
//       {value:"Region", widthPx: 160,
//       style: { font: { sz: "24", bold: true } },},
//       {value:"Nationality", widthPx: 160,
//       style: { font: { sz: "24", bold: true } },},
//       {value:"Salary Range", widthPx: 160,
//       style: { font: { sz: "24", bold: true } },},
//       {value:"Email Address", widthPx: 160,
//       style: { font: { sz: "24", bold: true } },},
//       {value:"Mobile", widthPx: 160,
//       style: { font: { sz: "24", bold: true } },}
//     ],
//     data: newitemList //props.itemList


//     } ]
    setMultiDataSet(styledMultiDataSet)

},[])

 
 
        return (
            <div>
            <ExcelFile element={<button className="inline-flex ml-3 items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >Excel</button>}>

                <ExcelSheet data={props.itemList} name="ApplicantsInfo">
                    <ExcelColumn label="Job Source" value="source"/>
                    <ExcelColumn label="Created Date" value="createdate"/>
                    <ExcelColumn label="Position1" value="position1"/>
                    <ExcelColumn label="Position2" value="position2"/>
                    <ExcelColumn label="Region" value="region"/>
                    <ExcelColumn label="Nationality" value="nationality"/>
                    <ExcelColumn label="Salary Range" value="salary"/>
                    <ExcelColumn label="Email Address" value="emailaddress"/>
                    <ExcelColumn label="Mobile" value="mobile"/>
                </ExcelSheet>

            </ExcelFile>
            </div> );
        // return (
        //     <div>
        //       { multiDataSet? <ExcelFile element={<button>Download Data With Styles</button>}>
        //             <ExcelSheet dataSet={multiDataSet} name="Organization"/>
        //         </ExcelFile> : "" } 
        //     </div>
        // );
    
}

export default ExcelDownload